@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.aboutsec_job {
  background: #000;
  padding-top: 246px;

  @include mobile {
    padding-top: 120px;
  }

  .aboutparent {
    padding-bottom: 126px;

    @include mobile {
      padding-bottom: 40px;
    }

    .title {
      background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
      font-size: 28px;
      font-weight: 500;
      letter-spacing: -0.2px;
      text-align: left;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-bottom: 25px;
      z-index: 1;

      @include mobile {
        text-align: center;
        padding-bottom: 8px;
        font-size: 28px;
      }
    }
    .boxsec {
      display: flex;
      justify-content: space-between;

      @include mobile {
        flex-flow: column;
      }
      .maincont {
        width: 100%;
        max-width: 450px;
        font-size: 39px;
        font-weight: 400;
        color: #fff;
        line-height: normal;
        @include mobile {
          font-size: 23px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 16px;
        }
      }
      .subcontent {
        width: 100%;

        .title-md {
          padding: 0;
          margin: 0;
          font-size: 26px;
          font-weight: 500;
          color: $white;
          line-height: 1.5;

          @include mobile {
            font-size: 16px;
            font-weight: 500;
            text-align: center;
          }
        }

        .subbox {
          display: flex;
          justify-content: space-between;
          margin-top: 64px;
          max-width: 60%;
          @include mobile {
            flex-flow: row wrap;
          }
          > div {
            margin-right: 40px;
            position: relative;
            @include mobile {
              width: 50%;
              margin-right: 0;
              margin-bottom: 50px;
            }
            &:before {
              content: "";
              width: 32px;
              height: 2px;
              background-image: linear-gradient(to top, #c87efb, #6fbaff);
              position: absolute;
              top: 0;
              left: 0;
            }
            .location {
              color: rgba($color: #fff, $alpha: 0.7);
              font-size: 16px;
              font-weight: 500;
              letter-spacing: -0.6px;
              padding-top: 10px;
              padding-bottom: 10px;
            }
            .locationname {
              letter-spacing: -0.2px;
              color: #ffffff;
              font-size: 20px;
              font-weight: 600;
              @include mobile {
                font-size: 18px;
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .whowearebox {
    @include mobile {
      width: calc(100% + 60px);
      margin-left: -30px;
    }
    .container {
      border-radius: 80px;
      background-color: #fafafa;
      padding: 126px 76px 92px 94px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @include mobile {
        border-radius: 0;
        padding: 69px 24px 65px 24px;
        flex-flow: column;
      }
      .imagebox {
        position: relative;
        max-width: 328px;
        width: 100%;
        position: relative;
        height: 491px;
        > img {
          height: 100%;
          width: 89%;
          object-fit: cover;
          -webkit-mask-size: cover;
          mask-size: cover;
          -webkit-mask-image: url("../../../assets/shapes/mask.svg");
          mask-image: url("../../../assets/shapes/mask.svg");
        }
        @include mobile {
          max-width: 256px;
          margin-right: auto;
          margin-left: auto;
          margin-bottom: 80px;
        }
        img {
          width: 90%;
        }
        .name {
          font-size: 25px;
          font-weight: 600;
          color: #000000;
          letter-spacing: -0.02px;
          position: absolute;
          bottom: -12px;
          line-height: normal;
          right: 0;
          @include mobile {
            font-size: 24px;
          }
          span {
            display: block;
            font-size: 16.4px;
            font-weight: 600;
            color: #f09b26;
            letter-spacing: -0.02px;
            text-transform: uppercase;
            @include mobile {
              font-size: 14px;
            }
          }
        }
      }
      .contentbox {
        width: 100%;
        max-width: 530px;
        .title {
          font-size: 40px;
          font-weight: 800;
          color: #000000;
          padding-bottom: 10px;
          @include mobile {
            font-size: 24px;
          }
        }
        .text {
          p {
            padding: 0;
            margin: 0;
            padding-bottom: 15px;
            font-size: 20.8px;
            color: rgba($color: #000000, $alpha: 0.7);
            letter-spacing: -0.18px;
            @include mobile {
              font-size: 16px;
              padding-bottom: 8px;
            }
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  .expectfromyou {
    padding-top: 160px;
    max-width: 982px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &:before {
      position: absolute;
      max-width: 0 auto;
      content: "";
      background: url("../../../assets/images/jobdetails/expectfromyou.png") no-repeat;
      background-size: contain;
      left: 29%;
      right: 30%;
      top: -10px;
      width: 440px;
      height: 440px;
      z-index: 1;

      @include mobile {
        left: 0;
        right: 0;
        top: -50px;
        width: 300px;
        height: 300px;
      }

      animation: clockwiseSpin 10s infinite linear;

      @keyframes clockwiseSpin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    @include mobile {
      padding-top: 135px;
    }
    .title {
      font-size: 40px;
      font-weight: 800;
      letter-spacing: -1.2px;
      text-align: center;
      color: #ffffff;
      z-index: 1;
      position: relative;
      padding-bottom: 72px;
      @include mobile {
        font-size: 24px;
      }
    }
    .lists {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: -0.2px;
          line-height: normal;
          color: rgba($color: #fff, $alpha: 0.7);
          padding-bottom: 15px;
          display: flex;
          align-items: flex-start;
          line-height: 1.5;
          @include mobile {
            font-size: 16px;
          }
          &:before {
            content: "";
            min-width: 8px;
            min-height: 8px;
            border-radius: 50%;
            background: linear-gradient(to top, #958ff1, #df52dd, #eb854d);
            margin-right: 15px;
            display: flex;
            margin-top: 7px;
          }
        }
      }
    }
    &.skills_looking {
      padding-bottom: 203px;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        background: url("../../../assets/images/jobdetails/skillslooking.png") no-repeat;
        background-size: contain;
        max-width: 0 auto;
        left: 29%;
        right: 30%;
        top: -10px;
        width: 440px;
        height: 440px;
        @include mobile {
          left: 0;
          right: 0;
          top: -50px;
          width: 300px;
          height: 300px;
        }

        animation: clockwiseSpin 10s infinite linear;

        @keyframes clockwiseSpin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }

      @include mobile {
        background-size: 100%;
        background-position: 0 50px;
        padding-top: 75px;
        padding-bottom: 122px;
      }
    }
  }
}
