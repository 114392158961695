@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.button-style {
  .btn {
    font-size: 16px;
    font-weight: 500;
    min-height: 44px;
    border-radius: 50px;
    min-width: 124px;
    transition: all 0.5s ease-in-out;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .black {
    background-color: #121212;
    color: $white;
  }

  .purple {
    background-color: $theme-purple;
    color: $white;
  }

  .green {
    background-color: $theme-green;
    color: $white;
  }
}
