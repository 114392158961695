@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.independent-section-style {
  min-height: 608px;
  width: 100%;
  background: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  position: relative;
  overflow: hidden;

  @include mobile {
    min-height: auto;
    padding: 100px 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .bg1 {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;

    &:after {
      position: absolute;
      top: 0;
      left: 0%;
      width: 100%;
      height: 100%;
      content: '';
      background: url('../../../assets/images/investment-page/bg/a1.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0%;
      width: 100%;
      height: 100%;
      content: '';
      background: url('../../../assets/images/investment-page/bg/a2.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .bg2 {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;

    &:after {
      position: absolute;
      top: 0;
      left: 0%;
      width: 100%;
      height: 100%;
      content: '';
      background: url('../../../assets/images/investment-page/bg/a3.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;

      @include mobile {
        background-size: cover;
      }
    }
  }
  
  .title {
    font-size: 57px;
    font-weight: 600;
    margin: 0 auto;
    max-width: 90%;

    @include mobile {
      font-size: 26px;
      max-width: 100%;
      line-height: 1.3;
    }

    br {
      @include mobile {
        display: none;
      }
    }
  }
}
