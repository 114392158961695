@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.ourPortfolio-root {
  min-height: 100vh;
  background-image: linear-gradient(to bottom, #ffffff, #f3f3f8, #f3f3f8, #ffffff);
  padding: 100px 0;

  @include mobile {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f3f3f8;
    background-image: none;
  }

  .sectionDetails {
    margin-bottom: 70px;

    @include mobile {
      margin-bottom: 30px;
    }

    h5,
    h1 {
      margin: 0;
    }

    h5 {
      color: #666671;
      @include mobile {
        font-size: 16px;
      }
    }

    h1 {
      font-weight: bolder;
      font-size: 44px;

      margin-bottom: 10px;

      @include mobile {
        font-size: 32px;
      }
    }

    p {
      margin: 0;

      font-size: 18px;
    }
  }

  .portfolio-cards-with-image {
    color: $white;

    @include desktop {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
    }

    @include mobile {
      display: flex;
    }

    .primary-slider-container {
      padding-left: 20px;
    }

    .element-wrapper {
      @include mobile {
        width: 70%;
        margin-right: 10px;
      }

      .element {
        position: relative;
        width: 100%;
        height: 420px;
        border-radius: 20px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        > .tagName {
          position: absolute;
          display: flex;
          z-index: 99;
          background: #8a2eed;
          padding: 5px 10px;
          border-radius: 8px;
          font-size: 10px;
          top: 10px;
          right: 10px;
        }

        @include mobile {
          height: 290px;
        }

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
          background-color: rgba($black, 0.4);
        }

        &:hover .hover-content {
          display: block;
          opacity: 1;
          transform: translateY(0px);
        }

        &:hover .front-content {
          opacity: 0;
          transform: translateY(-20px);
        }

        .front-content {
          width: 100%;
          height: 100%;
          padding: 24px 30px;
          border-radius: 20px;
          transition: all 0.2s ease-in-out;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          position: relative;

          @include mobile {
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 24px 20px;
          }

          .left {
            font-size: 54px;
            font-weight: bold;
            width: 70%;

            @include mobile {
              width: 100%;
            }

            &.md {
              font-size: 30px;
              line-height: 1.2;

              @include mobile {
                font-size: 22px;
              }
            }

            &.lg {
              font-size: 40px;
              line-height: 1.2;

              @include mobile {
                font-size: 18px;
              }
            }

            @include mobile {
              font-size: 32px;
            }
          }

          .right {
            font-size: 20px;
            line-height: 24px;

            .text {
              font-size: 15px;
            }

            @include mobile {
              font-size: 20px;
              padding-bottom: 0;
            }
          }
        }

        .hover-content {
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          padding: 40px 32px;
          border-radius: 20px;
          background-color: rgba($color: #000000, $alpha: 0.6);
          opacity: 0;
          transition: all 0.2s ease-in-out;
          width: 100%;

          @include mobile {
            padding: 30px 20px;
          }

          .catagory {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 60px;
            text-transform: uppercase;
            margin-top: 30px;
          }

          .description {
            font-weight: bold;
            font-size: 26px;

            @include mobile {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .portfolio-cards-with-logos {
    color: $white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 25px;

    @include mobile {
      grid-template-columns: 1fr 1fr 1fr;
      margin: 25px 0px;
    }

    .element-wrapper {
      .element {
        position: relative;
        height: 190px;
        padding: 10px;
        border-radius: 20px;
        background-color: $white;
        cursor: pointer;
        overflow: hidden;

  
        > .portfoliotag {
          position: absolute;
          display: flex;
          z-index: 99;
          background: #8a2eed;
          padding: 5px 10px;
          border-radius: 8px;
          font-size: 10px;
          top: 10px;
        }

        @include mobile {
          height: 129px;
          padding: 10px;
        }

        &:hover .hover-content {
          display: block;
          opacity: 1;
          transform: translateY(0px);
        }

        .front-content {
          width: 100%;
          height: 100%;
          border-radius: 20px;
          padding: 10px;
          place-items: center;
          justify-content: center;
          position: relative;
          transition: all 0.2s ease-in-out;

          @include mobile {
            height: 100%;
            overflow: hidden;
            border-radius: 0px;
          }

          img {
            width: 100%;

            @include mobile {
              width: 100%;
              transform: scale(1.5);
            }
          }
        }

        .hover-content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 20px;
          overflow: hidden;
          background: linear-gradient(136.75deg, #af5bf7 1.83%, #4561ff 98.96%);
          transition: all 0.2s ease-in-out;
          transform: translateY(10px);
          opacity: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @include mobile {
            padding: 10px 0;
          }

          .text {
            height: 100%;
            width: 100%;
            justify-content: center;
            place-items: center;
          }

          .title-text {
            max-width: 90%;
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            text-align: center;
            margin: 50px auto 0;

            @include mobile {
              font-size: 10px;
              max-width: 90%;
              line-height: 1.5;
              margin: 18px auto 0;
            }
          }

          .subText {
            position: absolute;
            padding-bottom: 15px;
            bottom: 0px;
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mobile {
              font-size: 14px;
            }

            img {
              margin-right: 4px;

              @include mobile {
                max-width: 15px;
                margin-right: 2px;
              }
            }
          }
        }
      }
    }
  }

  .flickity-page-dots {
    display: none;
  }
  .ribbon {
    height: auto;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 9;

    .ribbon-tag {
      padding: 5px 20px;
      min-width: 150px;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      text-align: center;
      margin-right: 16px;
      box-shadow: 0 5px 32px rgba($black, 0.1);

      @include mobile {
        min-width: 100%;
        font-size: 10px;
        padding: 5px 15px;
        text-align: center;
      }

      &:after {
        height: 0;
        width: 0;
        top: 0px;
        right: -13px;
        border-right: 13px solid transparent;
        content: "";
        position: absolute;
        z-index: 1;

        @include mobile {
          display: none;
        }
      }
    }

    @include mobile {
    &.ribbon-large {
      overflow: visible;

      .ribbon-tag {

        &:after {
          display: block;
          transform: scale(0.81);
          top: -4px;
          right: -11px;
        }
      }
    }
  }

    .firstcheck {
      background: linear-gradient(136.75deg, #af5bf7 1.83%, #4561ff 98.96%);

      &:after {
        border-top: 16px solid #4561ff;
        border-bottom: 16px solid #4561ff;
      }
    }
    .shutdown {
      background-color: #9C9C9C;

      &:after {
        border-top: 16px solid #9C9C9C;
        border-bottom: 16px solid #9C9C9C;
      }
    }

    .zomoto {
      background: linear-gradient(136.75deg, #e74759 1.83%, #df3091 98.96%);

      @include mobile {
        min-width: 100%;
        font-size: 8px;
        padding: 5px 5px;
        text-align: center;
      }

      &:after {
        border-top: 16px solid #df3091;
        border-bottom: 16px solid #df3091;
      }
    }
  }

  @include mobile {
    .small-cards {
      .ribbon {
        width: 100%;
        height: 100%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          object-fit: contain;
        }
      }

      .front-content {
        img {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }

  @include mobile {
    .large-card {
      flex-wrap: nowrap;
      overflow-x: scroll;
      padding: 0 20px;
      justify-content: flex-start !important;
      @include scroll-hide();

      .element-wrapper {
        width: 100%;
        min-width: 200px;
        margin-left: 0;
      }

      .element {
        height: 200px !important;
      }
    }
  }

  .tabButtonSec {
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    @include mobile {
      flex-flow: nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-x: scroll;

      @include scroll-hide();
    }

    > div {
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      margin-right: 4px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      background: $white;

      @include mobile {
        padding: 5px 20px;
        font-size: 15px;
      }

      &.active {
        background: #8a2eed;
        border-color: transparent;
        color: #fff;
      }
    }
  }
}
