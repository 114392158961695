@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.ourcultureParent {
  background: $white;
  padding: 100px 0;
  border-radius: 55px 55px 0 0;

  @include mobile {
    padding: 50px 0;
  }

  .titlearea {
    .title {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-size: 54px;
      font-weight: 800;
      letter-spacing: -1.55px;
      color: #000000;
      @include mobile {
        font-size: 28px;
      }
    }

    .text {
      font-size: 16px;
      letter-spacing: -0.2px;
      text-align: center;
      color: rgba($color: #000000, $alpha: 0.7);
      line-height: 24px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      font-weight: normal;

      @include mobile {
        font-size: 16px;
        max-width: 90%;

        br {
          display: none;
        }
      }
    }
  }

  .boxes_parent {
    position: relative;
    margin: 100px 0;
    margin-left: 150px;

    @include desktop-lg {
      margin-left: 150px;
    }
    @include mobile {
      margin-left: 0px;
    }

    .animated-image {
      position: absolute;
      top: -100px;
      left: 0;
      animation: rotation 15s infinite linear;

      &.fbg {
        width: 571px;
        height: 445px;
        left: -60px;

        @include mobile {
          width: 401px;
          height: 325px;
        }
      }
      &.sbg {
        width: 396px;
        height: 343px;

        @include mobile {
          width: 401px;
          height: 325px;
        }
      }
      &.tbg {
        width: 404px;
        height: 343px;

        @include mobile {
          width: 401px;
          height: 325px;
        }
      }
      &.fobg {
        width: 396px;
        height: auto;
        @include mobile {
          width: 401px;
          height: 325px;
        }
      }
    }
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }

    .box {
      @include mobile {
        width: 100%;
        
        &:nth-child(odd) {
          max-width: 80%;
        }
        &:nth-child(even) {
          max-width: 80%;
          margin-left: auto;
          margin-right: 0;
          text-align: right;

          .text {
            margin-left: auto;
          }
        }
      }
      .title {
        font-size: 35px;
        font-weight: 800;
        letter-spacing: -0.65px;
        color: #391162;
        position: relative;
        z-index: 1;

        @include mobile {
          font-size: 24px;
          line-height: normal;
          padding-bottom: 16px;
        }
      }
      .text {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.4px;
        color: rgba($black, 0.7);
        line-height: 28px;
        padding-bottom: 107px;
        font-weight: normal;
        max-width: 80%;
        position: relative;
        z-index: 1;

        @include mobile {
          font-size: 15px;
          padding-bottom: 72px;
          font-weight: 500;
          max-width: 100%;
        }
      }
      &.second {
        .title {
          color: #034143;
        }
      }
      &.third {
        .title {
          color: #034143;
        }
      }
    }
  }

  .culture-box {
    position: relative;
    min-height: 100vh;

    @include mobile {
      min-height: auto;
      padding: 0 15px 100px;
    }
  }
  .culture_images_box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 9;
    width: 70%;
    flex-wrap: wrap;
    margin: 0 auto;
    left: 0;
    right: 0;

    @include mobile {
      width: 100%;
    }

    .cultureimgpart {
      padding: 8px;
      width: 20%;
      position: absolute;
      transition: all 0.5s ease-in-out;

      @include mobile {
        width: 100%;
        position: relative;
      }

      &:nth-child(1) {
        left: 30%;
        top: 0px;

        @include mobile {
          left: 0%;
        }
      }

      &:nth-child(2) {
        right: 30%;
        top: 0px;

        @include mobile {
          right: 0%;
          top: 0px;
        }
      }

      &:nth-child(3) {
        left: 30%;
        top: 35%;

        @include mobile {
          left: 0%;
          top: 0%;
        }

        @include desktop-lg {
          top: 38.5%;
        }
      }

      &:nth-child(4) {
        right: 30%;
        top: 35%;

        @include mobile {
          left: 0%;
          top: 0%;
        }

        @include desktop-lg {
          top: 38.5%;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .animationActive {
    min-height: 80vh;

    .culture_images_box {
      transition: all 0.5s ease-in-out;

      .cultureimgpart {
        padding: 8px;
        width: 20%;
        position: absolute;
        transition: all 0.5s ease-in-out;

        &:nth-child(1) {
          left: 10%;
          top: 0px;
        }

        &:nth-child(2) {
          right: 50%;
          top: 0px;
        }

        &:nth-child(3) {
          left: 50%;
          top: 0%;
        }

        &:nth-child(4) {
          right: 10%;
          top: 0;
        }

        img {
          width: 100%;
        }
      }
    }

    &::after {
      content: "";
      width: calc(10% - 15px);
      background-image: linear-gradient(to right, #eae9f5, #ffffff);
      height: 44vh;
      position: absolute;
      right: -20px;
      top: -20px;
      border-radius: 50px 0 0 50px;
      transform: all 0.5s ease-in-out;
    }

    &:before {
      background-image: linear-gradient(to left, #eae9f5, #ffffff);
      margin-right: 15px;
      position: absolute;
      content: "";
      width: calc(10% - 15px);
      top: -20px;
      left: -20px;
      height: 44vh;
      content: "";
      border-radius: 0 50px 50px 0;
      transform: all 0.5s ease-in-out;
    }
  }
}
