@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.fuelling-imagination-style {
  background-color: $white;
  position: relative;
  padding: 150px 0;

  @include mobile {
    padding: 200px 0;
    p {
      margin: 30px;
    }
  }

  .title {
    font-size: 45px;
    font-weight: 800;
    color: $black;

    @include mobile {
      font-size: 28px;
    }
  }
  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    height: 10px;
  }

  #customers td {
    padding: 20px;
    height: 30px;
  }

  #customers tr {
    background-color: #f2f2f2;
  }

  #customers tr:nth-child(even) {
    background-color: white;
  }

  #customers tr:hover {
    background-color: #ddd;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
  }

  .subtitle {
    font-size: 30px;
    font-weight: 500;
    color: $black;

    @include mobile {
      font-size: 25px;
    }
  }

  .about-text {
    opacity: 0.7;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    max-width: 40%;
    // margin: 0 auto;

    @include mobile {
      max-width: 90%;
    }
  }

  .title-lg {
    font-size: 46px;
    font-weight: 800;
  }

  .description {
    font-size: 20px;
    font-weight: normal;
    color: $black;
    opacity: 0.7;
    max-width: 30%;
  }

  .text-purple {
    color: $theme-purple;
  }

  .text-green {
    color: $theme-green;
  }

  .content-section {
    position: relative;
    min-height: 400px;
  }

  .left-style {
    text-align: right;

    .description {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .bg1 {
    background: url("../../../assets//images/home/build-products/b1.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -150px;
    width: 713.9px;
    height: 556.6px;
  }

  .bg2 {
    background: url("../../../assets//images/home/build-products/b2.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -90px;
    width: 500px;
    height: 600px;
    right: 0;
  }

  .grid-style {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;

    @include mobile {
      grid-template-columns: 1fr 1fr;
    }

    .item {
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      .image-item {
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        transition: all 0.5s ease-in-out;

        @include mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          // padding-top: 44px;
        }
      }

      .title {
        font-size: 21.5px;
        text-align: center;
        max-width: 80%;
        position: relative;

        @include mobile {
          font-size: 15.5px;
        }
      }

      .content-more-info {
        position: relative;

        .description {
          max-width: 100%;
          color: $white;
          font-size: 14px;
        }

        .title-sm {
          font-size: 18px;
          font-weight: 600;
        }

        .text-lg {
          font-size: 30px;
          font-weight: 600;
        }

        .text1 {
          position: absolute;
          width: 100%;

          &:nth-child(1) {
            top: 25px;
            left: 80px;

            @include mobile {
              left: 0;
              top: 0px;
            }
          }
          &:nth-child(2) {
            top: 140px;
            text-align: right;
            right: 80px;
          }
          &:nth-child(3) {
            left: 80px;
            top: 260px;
          }
        }
      }

      &:nth-child(1) {
        display: flex;
        flex-direction: column;

        .title {
          color: $white;
          margin-top: 30px;
          text-align: center;
          min-width: 100%;
        }
      }

      &:nth-child(2) {
        display: flex;
        justify-content: center;

        .flex-image {
          display: flex;
          align-items: center;
          margin-top: 180px;
          animation: marquee 20s linear infinite;

          @keyframes marquee {
            0% {
              transform: translateX(0) scale(1.5);
            }
            100% {
              transform: translate(-100%) scale(1.5);
            }
          }
          @include rwd(414) {
            margin-top: 69px;
          }

          @include rwd(375) {
            margin-top: 55px;
          }

          @include mobile {
            margin-top: 40px;
          }
        }

        .animation-image {
          width: 100%;
        }

        .image2 {
          margin-left: 5px;
        }

        .title {
          color: $black;
          margin-top: 30px;
        }
      }

      &:nth-child(3) {
        display: flex;
        justify-content: flex-end;
        .title {
          color: $white;
          text-align: right;
          margin-top: 30px;
          margin-right: 30px;
        }
      }

      &:nth-child(4) {
        display: flex;
        justify-content: center;

        .title {
          color: $black;
          margin-top: 20px;
          text-align: left;
        }
      }

      &:nth-child(5) {
        display: flex;
        justify-content: center;

        .title {
          color: $white;
          margin-top: 50px;

          @include mobile {
            margin-top: 30px;
          }
        }
      }

      &:nth-child(6) {
        display: flex;
        align-items: center;

        .flex-image {
          display: flex;
        }

        .animation-image {
        }

        .image2 {
          margin-top: 15px;
        }

        .title {
          color: $black;
          text-align: left;
          max-width: 50%;
          padding-left: 30px;
        }
      }

      .content-more-info {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: $white;
      }

      &:nth-child(1) {
        height: 498px;
        background-color: $gray-e;
        padding: 10px;

        @include mobile {
          height: 223px;
        }
      }
      &:nth-child(2) {
        height: 325px;
        background-color: $gray-e;

        @include mobile {
          height: 145px;
          margin-top: 20px;
        }
      }
      &:nth-child(3) {
        height: 418px;
        background-color: $gray-e;

        @include mobile {
          height: 188px;
          margin-top: -2px;
          order: 2;
        }
      }
      &:nth-child(4) {
        height: 243px;
        background-color: $gray-e;

        @include mobile {
          height: 117px;
          margin-top: -3px;
        }
      }
      &:nth-child(5) {
        height: 415px;
        margin-top: -175px;
        background-color: $gray-e;

        @include mobile {
          height: 190px;
          margin-top: -61px;
        }
      }
      &:nth-child(6) {
        height: 320px;
        margin-top: -80px;
        background-color: $gray-e;

        @include mobile {
          height: 157px;
          margin-top: -20px;
        }
      }
    }
  }

  // achievement-sec
  .achievement-sec {
    margin-top: 90px;

    @include mobile {
      margin-top: 100px;
    }

    .achievement-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      @include mobile {
        margin-bottom: 0px;
        align-items: flex-start;
      }
    }

    .title-lg {
      font-size: 50px;
      font-weight: bold;
      position: relative;
      z-index: 1;

      @include mobile {
        font-size: 24px;
      }
    }

    .info-text {
      font-size: 22px;
      font-weight: 500;
      position: relative;
      z-index: 1;

      @include mobile {
        font-size: 18px;
        line-height: 1.3;
        opacity: 0.8;
      }
    }

    .img1 {
      background: url("../../../assets/images/home/fuelling-imagination/a3.svg");
      background-size: contain;
      width: 281px;
      height: 238px;
      position: absolute;
      background-repeat: no-repeat;

      @include mobile {
        width: 181px;
        height: 138px;
      }
    }
    .img2 {
      background: url("../../../assets/images/home/fuelling-imagination/a1.svg");
      background-size: contain;
      width: 281px;
      height: 238px;
      position: absolute;
      background-repeat: no-repeat;

      @include mobile {
        width: 181px;
        height: 138px;
      }
    }
    .img3 {
      background: url("../../../assets/images/home/fuelling-imagination/a2.svg");
      background-size: contain;
      width: 281px;
      height: 238px;
      position: absolute;
      background-repeat: no-repeat;

      @include mobile {
        width: 181px;
        height: 138px;
      }
    }

    .img1,
    .img2,
    .img3 {
      overflow: hidden;
      left: -40px;
      // border-radius: 50%;
    }

    .img2 {
      animation: antiClockwiseSpin 10s infinite linear;

      @keyframes antiClockwiseSpin {
        from {
          transform: rotate(360deg);
        }
        to {
          transform: rotate(0deg);
        }
      }
    }

    .img1,
    .img3 {
      animation: clockwiseSpin 10s infinite linear;

      @keyframes clockwiseSpin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  // fuelling3
  .json-style {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .flex-image {
    video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include mobile {
        left: 0;
        top: 0;
        transform: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .folder-icon {
    width: 50px;
    position: relative;
    margin-right: 10px;
    display: inline-block;

    .img-folder {
      position: absolute;
      width: 100%;
      top: -10px;

      & + img {
        position: relative
      }
    }
  }
}
