@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.appannie-parent {
  padding-bottom: 200px;
  position: relative;

  &::before {
    content: "";
    background: url("../../../assets/images/shape-purple.svg") no-repeat;
    width: 700px;
    height: 700px;
    background-size: cover;
    position: absolute;
    top: -32vw;
    left: -15vw;
    transform: scale(0.5);
    background-position: top left;

    @include mobile {
      display: none;
    }

    @include desktop-lg {
      top: -17vw;
      left: -10vw;
    }
  }

  @include mobile {
    padding-bottom: 150px;
  }

  .contentbox {
    display: flex;
    justify-content: center;
    flex-flow: column;

    .title {
      font-size: 50px;
      font-weight: 700;
      color: #fff;
      line-height: normal;
      margin-bottom: 100px;

      @include mobile {
        font-size: 30px;
        margin-bottom: 60px;
        text-align: center;
      }
    }

    .number-section {
      margin-top: 70px;
      display: flex;

      @include mobile {
        margin-top: 30px;
      }

      .box {
        position: relative;
        margin-right: 50px;
        width: 50%;

        @include mobile {
          text-align: center;
          margin-right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;

          &:last-child {
            margin-right: 0;
          }
        }

        &:nth-child(1) {
          &::before {
            content: "";
            background: url("../../../assets/images/aboutus/shadows/a1.svg") no-repeat;
            width: 163px;
            height: 136px;
            background-size: contain;
            position: absolute;
            top: -10px;
            left: -40px;
            right: 0;
            animation: clockwiseSpin 5s linear infinite;

            @keyframes clockwiseSpin {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }

            @include mobile {
              width: 173px;
              height: 176px;
              top: -55px;
              left: -0px;
            }
          }
        }
        // &:nth-child(2) {
        //   &::before {
        //     content: "";
        //     background: url("../../../assets/images/aboutus/shadows/a2.svg") no-repeat;
        //     width: 163px;
        //     height: 136px;
        //     background-size: contain;
        //     position: absolute;
        //     top: 37px;
        //     left: -40px;
        //     animation: rotate 5s linear infinite;

        //     @include mobile {
        //       width: 173px;
        //       height: 176px;
        //       top: 25px;
        //       left: -20px;
        //     }
        //   }
        // }

        &:last-child {
          &::before {
            content: "";
            background: url("../../../assets/images/aboutus/shadows/a3.svg") no-repeat;
            width: 163px;
            height: 136px;
            background-size: contain;
            position: absolute;
            top: -10px;
            left: -40px;
            animation: antiClockwiseSpin 5s linear infinite;

            @keyframes antiClockwiseSpin {
              from {
                transform: rotate(360deg);
              }
              to {
                transform: rotate(0deg);
              }
            }

           
            @include mobile {
              width: 173px;
              height: 176px;
              top: -55px;
              left: -0px;
            }
          }
        }

        .no-txt {
          font-size: 32px;
          font-weight: bold;
          letter-spacing: -0.03px;
          text-align: left;
          color: $white;
          position: relative;
          z-index: 1;

          @include mobile {
            font-size: 20px;
          }
        }

        .title-text {
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          line-height: normal;
          z-index: 1;
          position: relative;

          @include mobile {
            font-size: 11px;
          }
        }
      }
    }
  }

  .imagebox {
    display: flex;
    @include mobile {
      margin-top: 50px;
    }

    img {
      margin-left: auto;
      width: 90%;
      @include mobile {
        width: 100%;
        margin: auto;
      }
    }
  }

  .info-section {
    @include mobile {
      text-align: center;
    }

    // gradient-title
    .gradient-title {
      font-size: 22px;
      font-weight: bold;
      background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      @include mobile {
        font-size: 18px;
      }
    }

    .title-md {
      font-size: 36px;
      font-weight: 600;
      color: $white;

      @include mobile {
        font-size: 24px;
      }
    }

    .description {
      font-size: 20px;
      font-weight: bold;
      color: $white;
      opacity: 0.3;
      max-width: 60%;
      margin-top: 20px;

      @include mobile {
        max-width: 100%;
        margin-top: 10px;
        font-weight: 600;
      }
    }
  }
}
