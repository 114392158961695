@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.whowe {
  background: $black;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0 150px;

  @include mobile {
    margin-top: -1px;
    padding: 100px 0 100px;
  }
  .innerwhowe {
    width: 100%;
    max-width: 1187px;
    margin: 0 auto;
    > div {
      width: 100%;
      .slick-list {
        .slick-track {
          > div {
            width: 33%;
            > div {
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              width: 343px;
              @include mobile {
                max-width: 292px;
                margin: 0 auto;
              }
            }
          }
        }
      }
      .slick-dots {
        @include mobile {
          display: flex !important;
          margin-top: 30px;
          list-style: none;
          justify-content: center;
          padding-left: 0;
          li {
            margin-right: 8px;
            &:last-child {
              margin-right: 0;
            }
            button {
              background: rgba(255, 255, 255, 0.15);
              width: 51px;
              height: 4px;
              border-radius: 3px;
              outline: none;
              border: none;
            }
            &.slick-active {
              button {
                background-image: linear-gradient(to top, #df52dd, #3e4df2);
              }
            }
          }
        }
      }

      .imgbox {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        height: 200px;

        div {
          width: 200px !important;
          height: 200px !important;
        }

        img {
          max-width: 198px;
          @include mobile {
          }
          max-width: 147px;
        }
      }
      .contentbox {
        .title {
          font-size: 42px;
          font-weight: 800;
          letter-spacing: -0.63px;
          text-align: center;
          color: #ffffff;
        }
        .cont {
          letter-spacing: -0.18px;
          text-align: center;
          color: rgba(255, 255, 255, 0.9);
          font-size: 16px;
          font-weight: 500;
          line-height: 26px;
          text-align: center;
          @include mobile {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }
}
