@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.ecosystem-style {
  background-color: $black;
  overflow: hidden;

  .first-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    // background-color: $black;

    @include mobile {
      background: $black;
      flex-direction: column;
      padding: 50px 0;
    }

    .flickity-page-dots {
      display: none;
    }

    br {
      @include mobile {
        display: none;
      }
    }

    // Ecosystem Section
    .ecosystem-section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 2;
      position: absolute;
      height: 100vh;
      padding: 20px 50px;
      left: 0;
      top: 0 !important;
      min-width: 50%;
      // opacity: 0;
      text-align: left;

      @include desktop-lg {
        left: 5%;
      }

      @include mobile {
        opacity: 1;
        position: relative;
        height: auto;
        padding: 20px;
        text-align: center;
      }

      .title1 {
        font-size: 60px;
        color: $white;
        line-height: 1.1;
        margin: 20px 0;

        @include mobile {
          font-size: 28px;
          font-weight: 800;
          line-height: 1.4;
          margin-top: 0;
        }
      }

      .title4 {
        font-size: 30px;
        color: $white;
        background-image: linear-gradient(to bottom, #56bbff, #a979ff, #ff6993);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: -0.03px;

        @include mobile {
          font-size: 19px;
          text-transform: uppercase;
        }
      }

      .description {
        color: $white;
        font-size: 18px;
        max-width: 45%;
        opacity: 0.7;

        @include desktop-lg {
          max-width: 45%;
        }

        @include mobile {
          max-width: 100%;
          font-size: 16px;
          line-height: 1.5;
          opacity: 0.7;
        }
      }
    }

    .icon-section {
      position: absolute;
      top: -110px;
      left: 0;
      right: 0;
      // opacity: 0;
      width: 100%;

      @include mobile {
        top: 0;
        opacity: 1;
        position: relative;
      }

      // shape-bg
      .shape-bg {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .img-section {
          width: 75%;
          height: 100%;
          margin-top: 150px;
          position: relative;
          margin: 150px -150px 0 auto;

          @include mobile {
            margin: 0;
            width: 100%;
            transform: scale(1.3);
          }
        }

        .img-triangle {
          width: 100%;
          height: 100%;
          bottom: 0;
          position: absolute;
        }

        .image {
          width: 100%;
          margin-bottom: 15px;
          transform: scale(0.7);
          position: relative;

          @include mobile {
            transform: scale(0.69);
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .second-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    @include mobile {
      display: none;
    }

    .icon-section {
      position: absolute;
      top: -150px;
      left: 0;
      right: 0;
      width: 100%;
      opacity: 0;

      // Safari Only
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          -webkit-transform: translate3d(0, 0, 0) scale(1.3) !important;
        }
      }

      @include mobile {
        top: 0;
        position: relative;
      }

      // shape-bg
      .shape-bg {
        display: flex;
        align-items: center;
        justify-content: center;

        .img-section {
          width: 75%;
          height: 100vh;
          margin-top: 150px;
          position: relative;

          @include mobile {
            margin: 0;
            width: 100%;
            transform: scale(1.5);
          }
        }

        .img-triangle {
          width: 100%;
          height: 100%;
          bottom: 0;
          position: absolute;
        }

        .image {
          width: 100%;
          margin-bottom: 15px;
          transform: scale(0.83);
          position: relative;
        }
      }
    }
  }

  .we-enable-section {
    position: relative;
    overflow: hidden;
    margin-top: -800px;
    z-index: 9999;

    @include mobile {
      margin-bottom: 50px;
      padding-top: 150px;
      margin-top: -200px;
    }

    .flickity-button {
      // width: 80px;
      height: 100%;
      border: none;
      position: absolute;
      bottom: 15%;
      background: none;
      z-index: 1;

      @include mobile {
        display: none;
      }

      &.previous {
        left: 0;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 120px;
          height: 100%;
          background: url("../../../assets/shapes/fade-right.svg");
          background-repeat: no-repeat;
          background-size: cover;
          content: "";
          transform: rotate(-180deg);
        }
      }

      &.next {
        right: 0;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          width: 120px;
          height: 100%;
          background: url("../../../assets/shapes/fade-right.svg");
          background-repeat: no-repeat;
          content: "";
          background-size: cover;
        }
      }

      svg {
        width: 30px;
        position: relative;
        z-index: 1;
        fill: white;
      }
    }

    .panel {
      width: 100%;
      margin-top: 50px;

      &#cardSlide03 {
        margin-bottom: 130px;
        @include mobile {
          margin-bottom: 0;
        }
      }
    }

    .custom-carousel {
      overflow: hidden;
      padding-left: 20px;

      @include mobile {
        padding-left: 20px;
      }

      .slider-item {
        height: 520px;
        width: 20%;
        position: relative;

        @include tablet {
          height: 330px;
        }

        @include rwd(1600) {
          height: 620px;
        }

        @include rwd(1900) {
          height: 780px;
        }

        @include mobile {
          width: 85%;
          height: 597px;
        }

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: contain;
        }
      }
    }

    .content-section {
      margin: 0 auto;
      text-align: center;
      color: $white;
      padding-bottom: 80px;

      @include mobile {
        max-width: 95%;
        padding: 50px 15px;

        br {
          display: none;
        }
      }

      .title1 {
        font-size: 54px;
        font-weight: 900;
        margin-bottom: 20px;

        @include mobile {
          font-size: 35px;
        }
      }

      .description {
        font-size: 16px;
        opacity: 0.7;
        max-width: 80%;
        margin: 0 auto;

        @include desktop-lg {
          max-width: 60%;
        }

        @include mobile {
          max-width: 100%;
        }
      }
    }
  }
}
