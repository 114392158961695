@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.link-section {
    text-decoration: none !important;
}

.positions_parent {
    padding: 51px 0 72px 0;
    border-bottom: 1px solid #7e7e7e;
    display: flex;
    justify-content: space-between;

    @include mobile {
        padding-top: 31px;
        padding-bottom: 31px;
        width: 100%;
        align-items: flex-start;
    }
    .title {
        font-size: 34px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 44px;
        letter-spacing: -0.6px;
        color: $white;
        padding-bottom: 10px;

        @include mobile {
            font-size: 18px;
            letter-spacing: -0.81px;
            max-width: 80%;
            line-height: 1.5;
        }
    }

    .link {
        display: flex;
        align-items: center;
        color: $white;

        img {
            width: 30px;
            margin-right: 10px;
        }

        span {
            @include mobile {
                display: none;
            }
        }
    }

    .content {
        width: 100%;
        display: flex;
        position: relative;
        align-items: flex-start;

        .text {
            max-width: 755px;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: -0.2px;
            text-align: left;
            color: rgba($color: $white, $alpha: 0.6);
            @include mobile {
                font-size: 16px;
                line-height: normal;
                max-width: 274px;
            }
        }
        a {
            display: flex;
            margin-left: auto;
            margin-right: 58px;
            align-items: center;
            font-weight: bold;
            letter-spacing: -0.1px;
            text-align: left;
            color: $white;
            text-decoration: none;
            @include mobile {
                font-size: 0;
                overflow: hidden;
                margin-right: 0;
                position: relative;
                top: -43px;
            }
            img {
                margin-right: 11px;
                width: 26px;
                height: 26px;
            }
        }
    }
    
}

.showmorebtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: #8a2eed;
    font-weight: bold;
    color: #ffffff;
    border-radius: 32px;
    display: flex;
    width: 147px;
    height: 42px;
    text-decoration: none;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}