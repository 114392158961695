@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.intro-main-section {
  overflow: hidden;
  min-height: 100vh;
  position: relative;

  @include mobile {
    min-height: 670px;
  }

  // video-frame
  .home-video-section {
    width: 100%;
    height: 100%;
    position: relative;

    @include mobile {
      background-color: $black;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include mobile {
        object-fit: contain;
        transform: rotate(90deg) scale(1.8);
      }
    }
  }

  // First Section
  .slide01 {
    position: absolute;
    height: 100vh;
    width: 100%;

    .background-container {
      width: 100%;
      height: 100%;
      z-index: 100;
    }

    .content-box {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      width: 100%;
      text-align: center;
      z-index: 999;
      top: 0;

      @include mobile {
        height: 670px;
        padding: 20px;
      }

      .decription {
        color: $white;
        font-size: 43.2px;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 0;
        text-align: center;

        @include mobile {
          font-size: 32px;
          line-height: 1.4;
          font-weight: bold;

          br {
            display: none;
          }
        }
      }
    }
  }
}
