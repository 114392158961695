@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.people-section-style {
  background-color: $white;
  border-radius: 80px;
  margin-top: -140px;
  position: relative;
  padding: 50px;

  @include mobile {
    border-radius: 40px;
    padding: 15px;
  }

  .inner-section {
    border-radius: 80px;
    overflow: hidden;
    background-image: linear-gradient(to bottom, #e9e9f2, $white);

    @include mobile {
      border-radius: 40px;
    }
  }

  .title {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    margin: 75px 0;

    @include mobile {
      font-size: 28px;
      max-width: 80%;
      margin: 25px auto;
    }
  }

  .box-col {
    @include mobile {
      padding: 5px;
    }
  }

  .logo-box {
    margin-bottom: 35px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile {
      margin-bottom: 5px;
    }

    .img-sec {
      height: auto;
      width: 160px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;

      @include mobile {
        width: 140px;
        margin-bottom: 5px;
      }
      & + p {
        background: #EAEAF3;
        padding: 8px 10px;
        font-size: 14px;
        border-radius: 5px;
        box-shadow: 0 5px 12px rgba($black,0.02);

        @include mobile {
          font-size: 12px;
          padding: 5px;
          margin: 0;
        }
      }
    }

    img {
      width: 100%;
    }
  }

  .logo-lg {
    .img-sec {
      // width: 93px;
    }

    img {
      // max-width: 120px;
      margin: 0 auto;
    }
  }

  .logo-small {
    text-align: center;

    img {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
