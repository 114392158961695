@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.teamparent {
  background: $white;
  border-radius: 55px 55px 0 0;
  background-image: linear-gradient(to bottom, #e9e9f2, #ffffff);

  .team_inner {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 0 auto;
    padding-top: 69px;

    @include mobile {
      padding-top: 54px;
    }
    .titlearea {
      .title {
        width: 297px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        font-size: 40px;
        font-weight: 800;
        letter-spacing: -1.55px;
        color: #000000;
        @include mobile {
          font-size: 28px;
        }
      }
      .text {
        font-size: 16px;
        letter-spacing: -0.2px;
        text-align: center;
        color: rgba($color: #000000, $alpha: 0.7);
        line-height: 24px;
        width: 100%;
        max-width: 681px;
        margin-left: auto;
        margin-right: auto;
        font-weight: normal;
        @include mobile {
          max-width: 308px;
          font-size: 16px;
        }
      }
    }
    .teammember_box {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      margin-top: 67px;

      @include mobile {
        padding-left: 20px;
        flex-flow: row;
        overflow-x: auto;

        @include scroll-hide();
        
      }

      .padding-sm {
        padding: 5px;
      }

      > div {
        width: 290px;
        overflow: hidden;
        @include mobile {
         width: auto;
         margin-right: 15px;
         padding-bottom: 35px;
        }

        .imagearea {
          height: 350px;
          border-radius: 20px;
          background-image: linear-gradient(to top, #fff, #fff, #fff);
          margin-bottom: 20px;
          display: flex;
          overflow: hidden;
          align-items: flex-end;
          position: relative;
          padding-top: 20px;

          @include mobile {
            height: 200px;

            img {
              width: 100%;
            }
          }


          &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: flex;
            opacity: 0;
            border-radius: 20px;
            transition: all 1s linear;
            position: absolute;
            background-image: linear-gradient(to top, #ffffff, #ffffff, #ffffff);
          }
          > img {
            width: 100%;
            margin-top: auto;
            z-index: 1;
            filter: grayscale(100%);
            transition: all 1s linear;
            position: absolute;
            bottom: 0;
            left: 0;
            object-fit: cover;
          }
          &:hover {
            &:after {
              position: absolute;
              background-image: linear-gradient(133deg, #56bbff, #a979ff, #ff6993);
              opacity: 1;
            }
            > img {
              filter: grayscale(0%);
            }
          }
        }
        .textarea {
          display: flex;
          flex-flow: column;
          margin-bottom: 29px;

          @include mobile {
            margin-bottom: 0px;
          }
          .name {
            text-align: center;
            letter-spacing: -0.23px;
            text-align: center;
            color: #000000;
            font-size: 18px;
            font-weight: 700;

            @include mobile {
              font-size: 15px;
            }
          }
          .designation {
            letter-spacing: -0.17px;
            text-align: center;
            color: rgba($color: #000000, $alpha: 0.7);
            font-size: 14px;
            text-align: center;
            font-weight: 500;
          }
        }
      }
    }
    padding-bottom: 60px;
    .showmorebtn {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      background-color: #8a2eed;
      font-weight: bold;
      color: #ffffff;
      border-radius: 32px;
      display: flex;
      width: 147px;
      height: 42px;
      text-decoration: none;
      margin-top: 40px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @include mobile {
        display: none;
      }
    }
  }
}
