@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.build-products-style {
  background-color: $white;
  position: relative;
  padding: 110px 0 0px;
  z-index: 9999;

  @include mobile {
    padding: 110px 0 140px;
  }

  .title {
    font-size: 40px;
    font-weight: 800;
    color: $black;
  }

  .about-text {
    opacity: 0.7;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    max-width: 70%;
    margin: 0 auto;

    @include desktop-lg {
      max-width: 50%;
    }

    @include mobile {
      max-width: 90%;
    }
  }

  .title-lg {
    font-size: 46px;
    font-weight: 800;

    @include mobile {
      font-size: 35px;
    }
  }

  .description {
    font-size: 20px;
    font-weight: normal;
    color: $black;
    opacity: 0.7;
    max-width: 73%;

    @include mobile {
      max-width: 100%;
    }
  }

  .text-purple {
    color: $theme-purple;
  }

  .text-green {
    color: $theme-green;
  }

  .company-section {
    display: flex;
    flex-wrap: wrap;
    opacity: 1;

    .icon-box {
      width: 18%;
      height: 80px;
      margin-right: 30px;
      margin-bottom: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        width: 20%;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      img {
        max-width: 100%;
        width: 100%;
        position: absolute;
        object-fit: contain;
      }
    }
  }

  .row-column-change {
    @include catch {
      flex-direction: column-reverse;
    }
  }

  .content-section {
    position: relative;
    min-height: 400px;

    @include mobile {
      min-height: auto;
      padding: 30px 0;
    }
  }

  a.viewmore_co {
    font-size: 16px;
    font-weight: 500;
    min-height: 44px;
    border-radius: 50px;
    min-width: 124px;
    transition: all 0.5s ease-in-out;
    background-color: #592b88;
    color: #ffffff;
    display: flex;
    max-width: 124px;
    height: 44px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }
  a.viewmore_pro {
    font-size: 16px;
    font-weight: 500;
    min-height: 44px;
    border-radius: 50px;
    min-width: 124px;
    transition: all 0.5s ease-in-out;
    background-color: #0a7276;
    color: #ffffff;
    display: flex;
    max-width: 124px;
    height: 44px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-left: auto;
    &:hover {
      opacity: 0.8;
    }
  }

  .left-style {
    text-align: right;

    .description {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .index-style {
    position: relative;
    z-index: 1;
  }

  .bg1 {
    background: url("../../../assets//images/home/build-products/b1.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -100px;
    width: 400px;
    height: 400px;
    border-radius: 50px;
    left: 0;
    transform-origin: center;
    // background-color: red;
    animation: clockwiseSpin 10s infinite linear;

    @keyframes clockwiseSpin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @include mobile {
      background-size: contain;
      top: -20px;
      width: 300px;
      height: 300px;
    }
  }

  .bg2 {
    background: url("../../../assets//images/home/build-products/b2.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -140px;
    width: 500px;
    height: 600px;
    right: 10px;
    animation: clockwiseSpin 10s infinite linear;

    @keyframes clockwiseSpin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @include mobile {
      background-size: contain;
      width: 400px;
      height: 400px;
      right: -60px;
      top: -60px;
    }
  }
}
