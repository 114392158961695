@import "../../styles/variables/mixins";
@import "../../styles/variables/colors";
.careers-root {
  overflow: scroll;
  height: 100vh;
  scroll-snap-type: proximity;
  scroll-snap-points-y: repeat(100vh);
  position: relative;

  // remove the bellow line for removing the scroll effect
  scroll-snap-type: y proximity;
  overflow-x: hidden;
  background: $black;
  nav {
    .hamburguer {
      .lines {
        background-color: $white;
      }
    }
  }
}
.scroll-snap-proximity {
  scroll-snap-align: start;
  position: relative;
}
.openings_parent {
  padding-top: 170px;
  background: #000;
  padding-bottom: 48px;
  @include mobile {
    padding-top: 94px;
    background: #000;
    padding-bottom: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }
  > div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    > .title {
      background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
      font-size: 22px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.03px;
      text-align: left;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
      @include mobile {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
    .subtitle {
      font-size: 54px;
      font-weight: 600;
      font-style: normal;
      line-height: 61px;
      letter-spacing: -0.81px;
      color: #ffffff;
      max-width: 730px;
      padding-bottom: 80px;
      @include mobile {
        font-size: 28px;
        line-height: normal;
        padding-bottom: 33px;
      }
    }
    .viewAllbtn {
      width: 273px;
      height: 57px;
      border-radius: 32px;
      background-color: rgba($color: #fff, $alpha: 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 28px auto 0 auto;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.1px;
      text-align: left;
      color: #ffffff;
      cursor: pointer;
      img {
        width: 26px;
        margin-right: 10px;
      }
    }
  }
}
.tabButtonSec {
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @include mobile {
    flex-flow: nowrap;
    white-space: nowrap;
    justify-content: flex-start;
  }

  > div {
    cursor: pointer;
    padding: 5px 20px;
    border: 1px solid rgba(255, 255, 255, .6);
    border-radius: 20px;
    margin-right: 4px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: rgba(255, 255, 255, .6);

    @include mobile {
      margin-right: 10px;
    }

    &.active {
      background: #8a2eed;
      border-color: transparent;
      color: #fff;
    }
  }
}
.culturesec {
  background: #000;
  margin-top: -10px;
  position: relative;
}
.joinus {
  margin-top: -25px;
}
