@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.aboutbanner {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;


  .text-section {
    position: relative;
    z-index: 1;

    .pagetitle {
      background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
      text-align: center;
      font-family: "Inter";
      font-size: 32px;
      font-weight: bold;
      letter-spacing: -0.03px;
      text-align: center;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;

      @include mobile {
        font-size: 26px;
      }
    }
    .headtitle {
      font-family: "Inter";
      font-size: 50px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.5px;
      text-align: center;
      color: #ffffff;
      margin: 0 auto;

      @include mobile {
        font-size: 22px;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 100%;
      }
    }
    .headcontent {
      color: rgba(255, 255, 255, 0.9);
      letter-spacing: -0.5px;
      line-height: 29px;
      font-size: 18px;
      text-align: center;
      max-width: 60%;
      margin: 0 auto;

      @include mobile {
        font-size: 15px;
        line-height: 1.5;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 100%;
        margin-top: 10px;
      }
    }

  }
  // video-frame
  .about-video-section {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;

    video {
      width: 100%;
      height: 100vh;
      position: absolute;
      object-fit: cover;
    }
  }
}
