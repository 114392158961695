@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.combined-section {
  background: $black;
  position: relative;
  // padding: 100px 0;

  @include desktop-lg {
    min-height: 100vh;
  }

  .heading-section {
    margin: 0 auto;
    text-align: center;

    @include mobile {
      max-width: 80%;
    }

    br {
      @include mobile {
        display: none;
      }
    }

    .title-lg {
      color: $white;
      font-weight: bold;
      font-size: 46px;
      line-height: 1.3;

      @include mobile {
        font-size: 30px;
      }
    }
  }
  .primary-color-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 50px;
    .primary-color-text {
      width: 40%;
      .primary-color-heading {
        color: $white;
        font-weight: bold;
        font-size: 32px;
        line-height: 1.3;
      }
      .primary-color-description {
        color: $white;
        font-size: 15px;
        line-height: 1.3;
      }
    }
    .primary-color-shades {
      display: flex;
      width: 50%;
      border: 1px solid white;
      border-radius: 24px;
      overflow: hidden;
      min-height: 200px;

      .white-color {
        display: flex;
        background-color: white;
        align-items: flex-end;
        padding-left: 10px;
        width: 40%;

        p {
          width: 100%;
        }
      }
      .light-grey-color {
        background: #cdcccc;
        width: 15%;
      }

      .grey-color {
        background: #949495;
        width: 15%;
      }

      .dark-grey-color {
        background: #575759;
        width: 15%;
      }

      .black-color {
        background: black;
        width: 15%;
      }
    }
  }
  .secondary-color-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 50px;
    .primary-color-text {
      width: 40%;
      .primary-color-heading {
        color: $white;
        font-weight: bold;
        font-size: 32px;
        line-height: 1.3;
      }
      .primary-color-description {
        color: $white;
        font-size: 15px;
        line-height: 1.3;
      }
    }
    .secondary-color {
      &-shade {
        border-radius: 24px;
        width: 18%;
        position: relative;
        span {
          height: 30px;
          position: absolute;
          display: block;
          width: 60%;
        }
        &-name {
          &-1 {
            bottom: 0;
            background: #db38db;
            border-top-right-radius: 16px;
          }
          &-2 {
            top: 100%;
            background: #452bf0;
            border-bottom-right-radius: 16px;
          }
        }
      }
      &-shades {
        display: flex;
        width: 50%;
        min-height: 150px;
        justify-content: space-around;
      }
      &-1 {
        background: linear-gradient(158.04deg, #db38db 0%, #452bf0 100%);
      }
      &-2 {
        background: linear-gradient(158.04deg, #452bf0 0%, #90b9f9 100%);
      }
      &-3 {
        background: linear-gradient(158.04deg, #7af6ca 0%, #4b88f7 100%);
      }
      &-4 {
        background: linear-gradient(158.87deg, #f8d948 6.25%, #e78647 93.59%);
      }
      &-5 {
        background: linear-gradient(158.04deg, #db38db 0%, #452bf0 100%);
      }
    }
  }
}
