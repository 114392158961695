// ######################################## //
// ## FONTS.SCSS ## //
// ######################################## //

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/Inter-Thin.ttf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Inter-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/Inter-ExtraLight.ttf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/Inter-Light.ttf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Inter-Regular.ttf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Inter-Regular.ttf") format("truetype");
}


@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Inter-Medium.ttf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Inter-SemiBold.ttf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Inter-Bold.ttf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/Inter-ExtraBold.ttf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/Inter-Black.ttf"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Inter-Black.ttf") format("truetype");
}