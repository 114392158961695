@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.primary-slider-container {
  overflow: hidden;
  width: 100%;

  .flickity-page-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }

  .dot {
    width: 51px;
    height: 4px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.15);
    margin-right: 8px;
    overflow: hidden;

    &.is-selected {
      background-image: linear-gradient(to bottom, #df52dd, #3e4df2);
    }
  }
}
