@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.whatWeDo-root {
  background-color: $black;
  color: $white;
  padding: 120px 0;
  position: relative;

  @include mobile {
    padding-top: 0px;
    padding-bottom: 40px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: url("../../../assets/images/bg/a1.svg");
      background-size: cover;
      // background-color: red;
    }
  }

  .titleh3 {
    margin-bottom: 0;
    margin-top: 20px;

    @include mobile {
      font-size: 20px;
    }
  }

  .subtitle {
    font-size: 16px;
    opacity: 0.8;
    margin-bottom: 16px;

    @include mobile {
      font-size: 14px;
    }
  }

  .description {
    font-size: 18px;
    line-height: 1.5;
    opacity: 0.6;
    margin: 0 auto;

    @include mobile {
      font-size: 16px;
    }
  }

  .cards-row {
    .cards {
      text-align: center;

      @include mobile {
        padding: 40px 0;
        width: 100%;
      }

      img {
        // width: 60%;
        height: 200px;
        width: auto;
        margin-bottom: 30px;

        @include mobile {
          height: 140px;
        }
      }
    }
  }

  .bottom-button {
    padding-top: 20px;

    @include mobile {
      display: none;
    }

    button {
      background-color: #8a2eed;
      font-weight: bold;
      color: $white;
      padding: 8px 20px;
      border-radius: 32px;
    }
  }
  a {
    background-color: #8a2eed;
    font-weight: bold;
    color: #ffffff;
    padding: 8px 20px;
    border-radius: 32px;
    display: flex;
    width: 147px;
    height: 42px;
    text-decoration: none;
    margin-top: 40px;
  }
  

  .slick-list {
    .slick-track {
      > div {
        width: 33%;
        > div {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          width: 343px;
          @include mobile {
            max-width: 292px;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .slick-dots {
    @include mobile {
      display: flex !important;
      margin-top: 30px;
      list-style: none;
      justify-content: center;
      padding-left: 0;

      li {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }

        button {
          background: rgba(255, 255, 255, 0.15);
          width: 51px;
          height: 4px;
          border-radius: 3px;
          outline: none;
          border: none;
        }

        &.slick-active {
          button {
            background-image: linear-gradient(to top, #df52dd, #3e4df2);
          }
        }
      }
    }
  }
}
