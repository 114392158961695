@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.combined-section {
  background: $black;
  position: relative;

  @include desktop-lg {
    min-height: 100vh;
  }

  .heading-section {
    margin: 0 auto;
    text-align: center;

    @include mobile {
      max-width: 80%;
    }

    br {

      @include mobile {
        display: none;
      }
    }

    .title-lg {
      color: $white;
      font-weight: bold;
      font-size: 46px;
      line-height: 1.3;

      @include mobile {
        font-size: 30px;
      }
    }

    .description {
      color: $white;
      opacity: 0.7;
      font-size: 18px;
      margin-top: 5px;

      @include mobile {
        font-size: 14px;
      }
    }
  }
  
  .circle-image-section {
    display: flex;
    align-items: center;
    justify-content: center;

    @include desktop {
      background: url("../../../assets/images/bg/circles.png") center;
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 100vh;
      transform: scale(1.6);
    }

    @include desktop-lg {
      transform: scale(1.7);
    }

    @include mobile {
      position: relative;
      min-height: 60vh;

      &::before {
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../../assets/images/bg/circles.png") center;
        content: '';
        background-size: cover;
        transform: scale(1.8);
      }
    }

    .text-section {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      margin: 0 auto;

      @include mobile {
        width: 95%;
      }

      @include rwd(1280) {
        width: 20%;
      }
    }

    a {
      text-decoration: none;
      margin:0 0px;

      @include desktop-lg {
        margin: 0 40px;
      }

      @include mobile {
        margin: 0 20px;
      }
    }

    .innerbox {
      width: 100%;
      position: relative;

      @include mobile {
        width: 100%;
        margin: 0 auto;
      }
  
      &.right {
        text-align: right;
  
        .title {
          margin-right: 0;
          margin-left: auto;
        }
      }
  
      .title {
        letter-spacing: -0.6px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
        max-width: 80%;
  
        @include mobile {
          font-size: 18px;
        }
      }
      .content {
        color: rgba($white, 0.8);
        font-size: 12px;
        font-weight: 400;
        line-height: 1.6;
      }
    }
  }
}
