@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.top-banner {
  background-color: $black;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  color: $white;

  @include mobile {
    max-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .text-section {
    position: relative;
    z-index: 1;

    .pagetitle {
      background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
      text-align: center;
      font-family: "Inter";
      font-size: 32px;
      font-weight: bold;
      letter-spacing: -0.03px;
      text-align: center;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;

      @include mobile {
        font-size: 26px;
      }
    }
    .headtitle {
      font-family: "Inter";
      font-size: 50px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.5px;
      text-align: center;
      color: #ffffff;
      margin: 0 auto;

      @include mobile {
        font-size: 22px;
        max-width: 100%;
      }
    }
    .headcontent {
      color: rgba(255, 255, 255, 0.9);
      letter-spacing: -0.5px;
      line-height: 29px;
      font-size: 18px;
      text-align: center;
      max-width: 60%;
      margin: 0 auto;

      @include mobile {
        font-size: 15px;
        line-height: 1.5;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 100%;
        margin-top: 10px;
      }
    }

  }


  .container {
    display: flex;
    position: relative;
    height: 100vh;
    justify-content: center;
    place-items: center;

    @include mobile {
      max-height: 437px;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      
      .title {
        font-size: 54px;
        line-height: 1.3;
        max-width: 80%;

        @include mobile {
          font-size: 20px;
          font-weight: 600;
          max-width: 100%;
        }

        span {
          @include mobile {
            display: block;
          }
        }

        br {
          @include mobile {
            display: none;
          }
        }
      }
      
      .sub-title {
        margin-top: 20px;
        font-size: 18px;
        line-height: 36px;
        max-width: 60%;

        @include mobile {
          max-width: 95%;
          line-height: 1.3;
          font-size: 17px;
        }
      }
    }

    .scroll-text {
      position: absolute;
      bottom: 24px;
      font-size: 16px;

      @include mobile {
        display: none;
      }

      img {
        margin-right: 12px;
      }
    }
  }

    // video-frame
    .video-banner {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
  
      video {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
      }
    }
}
