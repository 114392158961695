@import "../../styles/variables/mixins";
@import "../../styles/variables/colors";

.career-page-detail {
  position: relative;
  background-color: #fafafa;

  nav {
    .hamburguer {
      .lines {
        background-color: #fff;
      }
    }
  }
  .perkssec {
    background: #000;
    @include mobile {
      margin-top: -1px;
    }
  }
}

