// ######################################## //
// ## COLORS.SCSS ## //
// ######################################## //

// Black (Gray) Color Shades
$black: #000000;
$gray-1: #111111;
$gray-2: #222222;
$gray-3: #333333;
$gray-4: #444444;
$gray-5: #555555;
$gray-6: #666666;
$gray-7: #777777;
$gray-8: #888888;
$gray-9: #999999;
$gray-a: #aaaaaa;
$gray-b: #bbbbbb;
$gray-c: #cccccc;
$gray-d: #dddddd;
$gray-e: #eeeeee;
$white: #ffffff;
$gray-dark: #c9cdd8;
$gray-light: #b7bac3;
$label-text: #6f7588;
$border-color: #e1e3ea;
$arrow-color: #a8b1ce;
$textColor: #21243c;

// Color Shades (Theme Based - Xclusive)
$theme-primary: #00d290;
$theme-secondary: #0f1938;
$theme-success: #037e56;
$theme-info: #9c6dff;
$theme-warning: #ff8238;
$theme-green : #0a7276;
$theme-purple-light:#8a2eed;
$theme-purple: #592b88;
$theme-warning-light: #fef6e0;
$theme-danger: #e24c4b;
$theme-yellow: #ea9d04;
$theme-cyan: #35d2cc;
$theme-pink: #c83375;
$theme-link: #004b8f;
