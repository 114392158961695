@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.JoinTeam {
  padding-top: 86px;
  padding-bottom: 60px;
  background: #fff;
  .introtext {
    width: 100%;
    max-width: 572px;
    margin: 0 auto;
    .title {
      display: flex;
      flex-flow: column;
      font-size: 44px;
      font-weight: 500;
      letter-spacing: -2.27px;
      text-align: center;
      color: #000000;
      line-height: normal;
      @include mobile {
        display: block;
        font-size: 36px;
      }
      span {
        font-weight: 800;
        line-height: normal;
      }
    }
    .content {
      margin-top: 7px;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: -0.03px;
      text-align: center;
      color: rgba($color: #000000, $alpha: 0.7);
      max-width: 552px;
      margin-left: auto;
      margin-right: auto;
      @include mobile {
        font-size: 16px;
      }
    }
  }
  .images {
    width: 100%;
    max-width: 872px;
    margin: 0 auto;
    margin-top: 51px;
    position: relative;
    > img {
      position: absolute;
      display: flex;
      bottom: 0;
      &.centerimage {
        position: relative;
        width: 323px;
        height: 483px;
        margin: 0 auto;
        z-index: 9;
        @include mobile {
          width: 124px;
          height: 187px;
        }
      }
      &.fimage {
        width: 254px;
        height: 381px;
        left: calc(50% + 37px);
        z-index: 2;
        bottom: 30px;
        @include mobile {
          width: 98px;
          height: 147px;
          left: calc(50% + 25px);
          bottom: 10px;
        }
      }
      &.simage {
        width: 214px;
        height: 321px;
        right: 57px;
        bottom: 28px;
        @include mobile {
          width: 82px;
          height: 124px;
          left: calc(50% + 90px);
          bottom: 10px;
        }
      }
      &.timage {
        width: 257px;
        height: 385px;
        left: calc(50% - 290px);
        z-index: 2;
        bottom: 30px;
        @include mobile {
          width: 99px;
          height: 149px;
          left: 53px;
          bottom: 10px;
        }
      }
      &.foimage {
        width: 226px;
        height: 339px;
        left: 65px;
        bottom: 30px;
        @include mobile {
          width: 87px;
          height: 131px;
          bottom: 10px;
          left: 0;
        }
      }
    }
  }
  .joinnowbtn {
    width: 208.5px;
    height: 74px;
    border-radius: 38.7px;
    background-color: #000000;
    margin-top: 41px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.67px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    
    @media screen and (min-height: 0) and (max-width: 767px) {
      width: 124px;
      height: 44px;
      font-size: 16px;
      margin-top: 63px;
    }
  }
}
