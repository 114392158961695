@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.anatomysec {
  background: $black;
  padding: 0px 0 150px;

  @include mobile {
    padding-top: 100px;
  }


  .video_box {
    width: 100%;
    position: relative;

    @include mobile {
      height: 200px;
      margin-bottom: 50px;

      .lottie-animation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(2);
      }
    }
  }
  .image_presentation {
    position: relative;
    display: flex;
    padding-right: 60px;
    padding-left: 105px;
    justify-content: space-between;
    @include mobile {
      padding-left: 15px;
      padding-right: 15px;
      flex-flow: column;
      align-items: center;
    }
    > div {
      max-width: 256px;
      &:last-child {
        min-width: 300px;
      }
      @include mobile {
        max-width: 100%;
      }
      .imagebox {
        display: flex;
        align-items: center;
        height: 252px;
        margin-bottom: 64px;
        @include mobile {
          height: auto;
          margin-bottom: 20px;
          justify-content: center;
        }
        img {
          &.apps {
            width: 76px;
            height: 76px;
          }
          &.colon {
            width: 78px;
            height: 160px;
          }
          &.semicolon {
            width: 78px;
            height: 206px;
          }
          &.appyhigh {
            width: 190px;
            height: 252px;
          }
        }
      }
      .contentbox {
        .title {
          font-size: 30px;
          font-weight: 700;
          letter-spacing: -1.25px;
          text-align: left;
          color: #ffffff;
          @include mobile {
            display: flex;
            justify-content: center;
            font-size: 26px;
          }
        }
        .cont {
          margin-top: 10px;
          letter-spacing: -0.18px;
          text-align: left;
          color: rgba($color: #f2f2f2, $alpha: 0.7);
          font-size: 16px;
          font-weight: 500;
          line-height: 26px;
          @include mobile {
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
    .plus_img {
      width: 24px;
      height: 24px;
    }
    .arrowimg {
      width: 111px;
      height: 61px;
      @include mobile {
        transform: rotate(90deg);
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }
    .centerimg {
      margin-top: 100px;
      margin-right: 50px;
      @include mobile {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  .era_sec {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    @include mobile {
      padding-bottom: 0;
      bottom: 50px;
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }

    .era_text {
      position: relative;
      max-width: 50%;
      margin: 0 auto;
      letter-spacing: -0.3px;
      text-align: center;
      color: rgba(255, 255, 255, 0.9);
      font-size: 42px;
      font-weight: 500;
      font-weight: bold;

      @include desktop-lg {
        max-width: 30%;
      }

      @include mobile {
        font-size: 24px;
        max-width: 100%;
        line-height: normal;
      }
    }
    .era_subtext {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.03px;
      text-align: center;
      color: rgba($color: $white, $alpha: 0.7);
      font-weight: 400;
      margin-top: 8px;
      max-width: 543px;
      margin-left: auto;
      margin-right: auto;

      @include mobile {
        font-size: 16px;
      }
    }
  }
}
