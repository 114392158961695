@import "../../styles/variables/mixins";
@import "../../styles/variables/colors";

.not-found {
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .text-section {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .text-404 {
      font-size: 105px;
      opacity: 0.2;

      @include mobile {
        font-size: 65px;
      }
    }

    .text-description {
      font-size: 45px;
      opacity: 0.6;

      @include mobile {
        font-size: 25px;
      }
    }
  }

  .back-btn-style {
    border: none;
    background: $theme-purple-light;
    color: $white;
    border-radius: 50px;
    font-weight: 600;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px 20px;
    margin-top: 20px;
    text-decoration: none;
  }
}
