@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.portfolio {
  background: $black;
  overflow: hidden;
  @include mobile {
    margin-top: -1px;
  }
  .introbox {
    width: 100%;
    max-width: 730px;
    margin: 0 auto;
    padding-bottom: 78px;
    @include mobile {
      padding-bottom: 41px;
    }
    .title {
      background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
      text-align: center;
      font-family: "Inter";
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.03px;
      text-align: center;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
    }
    .content {
      margin-top: 14px;
      letter-spacing: -0.81px;
      text-align: center;
      color: #ffffff;
      font-size: 54px;
      font-weight: 600;
      line-height: 61px;
      @include mobile {
        font-size: 28px;
        line-height: normal;
      }
    }
  }
  .portfolioBox {
    position: relative;
    width: 100%;
    max-width: calc(100% - 140px);
    margin: 0 auto;
    padding-bottom: 96px;
    overflow: hidden;
    @include mobile {
      max-width: 100%;
    }

    .react-tabs {
      > ul {
        width: 100%;
        max-width: 909px;
        margin: 0 auto;
        margin-bottom: 65px;
        border-radius: 72px;
        background-color: #121212;
        min-height: 70px;
        max-height: 70px;
        border: none;
        display: flex;
        justify-content: space-between;
        padding-right: 50px;
        padding-left: 50px;

        @include mobile {
          background-color: transparent;
          flex-flow: nowrap;
          overflow-y: scroll;
          padding: 0;
          max-height: 100%;
        }
        li {
          display: flex;
          align-items: center;
          width: 25%;
          background: transparent !important;
          border: none !important;
          box-shadow: none;
          outline: none;
          justify-content: center;
          padding: 0;
          letter-spacing: -0.81px;
          text-align: center;
          color: rgba($color: $white, $alpha: 0.3);
          font-weight: 400;
          font-size: 20px;
          transition: all 0.2s ease-in-out;
          &:first-child, &:nth-child(2), &:nth-child(3) {
            width: 15%;
          }
          @include mobile {
            width: auto !important;
            margin-bottom: 10px;
            height: 53px;
            padding: 0 15px;
            white-space: nowrap;
          }
          &:focus {
            &:after {
              display: none;
            }
          }
          &.react-tabs__tab--selected {
            color: $white;
            @include mobile  {
              border-radius: 50px;
              background-color: #121212 !important;
            }
          }
        }
      }
      > div {
        .card {
          padding: 16px;
          border-radius: 20px;
          background-color: #151515;
          width: 100%;
          margin-bottom: 32px;
          display: flex;
          flex-flow: row;
          padding-right: 0;
          padding-top: 49px;
          padding-left: 32px;
          @include mobile {
            flex-flow: column;
            padding-top: 24px;
            padding-left: 24px;
          }
          &::after {
            content: "";
            width: 30px;
            height: 100%;
            position: absolute;
            right: -13px;
            top: 0;
            background-image: linear-gradient(to left, #000, rgba(0, 0, 0, 0));
          }
          .contentArea {
            width: 50%;
            display: flex;
            @include mobile {
              width: 100%;
              flex-flow: column;
            }
            .detailsArea {
              width: 389px;
              margin-right: 50px;
              display: flex;
              flex-flow: column;

              .logobox {
                width: 70px;
                height: 70px;
                margin-bottom: 48px;
                img {
                  width: 100%;
                }
              }
              .category {
                letter-spacing: -0.03px;
                text-align: left;
                color: rgba($color: $white, $alpha: 0.7);
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 20px;
                text-transform: uppercase;
                line-height: normal;
                @include mobile {
                  position: absolute;
                  top: 30px;
                  left: 110px;
                  font-size: 12px;
                }
              }
              .name {
                font-size: 32px;
                font-weight: bold;
                font-stretch: normal;
                letter-spacing: -0.58px;
                text-align: left;
                color: #ffffff;
                margin-bottom: 15px;
                line-height: normal;
                @include mobile {
                  position: absolute;
                  left: 110px;
                  top: 63px;
                  font-size: 24px;
                }
              }
              .description {
                letter-spacing: -0.18px;
                text-align: left;
                color: rgba(255, 255, 255, 0.9);
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                @include mobile {
                  max-width: 300px;
                  font-size: 14px;
                  line-height: 19px;
                }
              }
              .availabletext {
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.58px;
                text-align: left;
                color: #ffffff;
                margin-bottom: 10px;
                @include mobile {
                  display: none;
                }
              }
              .available_box {
                display: flex;

                @include mobile {
                  // display: none;
                  margin-bottom: 50px;
                }
                > div {
                  &:first-child {
                    width: 138px;
                    margin-right: 17px;
                    img {
                      width: 100%;
                    }
                  }
                  &:last-child {
                    width: 138px;
                    img {
                      width: 100%;
                    }
                  }
                }
              }
            }
            .nosDownloads {
              width: 110px;
              justify-content: flex-end;
              display: flex;
              flex-flow: column;
              margin: 20px 0;

              @include mobile {
                flex-flow: row;
                width: 100%;
                justify-content: space-between;
                max-width: 300px;
              }
              > div {
                margin-bottom: 32px;
                .title {
                  letter-spacing: -0.58px;
                  text-align: left;
                  color: rgba($color: $white, $alpha: 0.5);
                  font-size: 14px;
                  font-weight: 500;
                }
                .numbers {
                  letter-spacing: -0.58px;
                  text-align: left;
                  color: #ffffff;
                  font-size: 24px;
                  font-weight: 700;
                  &.justlaunched {
                    font-size: 16px;
                  }
                  @include mobile {
                    font-size: 16px;
                  }
                }
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
          .slider {
            width: 50%;
            overflow: hidden;
            position: relative;
            @include mobile {
              width: 100%;
            }
            img {
              min-width: 279px;
              max-width: 279px;
              margin-right: 0;
              outline: none !important;
              box-shadow: none !important;
              border-radius: 18px;
              @include mobile {
                min-width: 121px;
                max-width: 121px;
              }
            }
            .slick-track {
              display: flex;
              > div {
                > div {
                  > div {
                    outline: none !important;
                    box-shadow: none !important;
                    margin: 5px;
                  }
                }
              }
            }
            .slick-prev {
              position: absolute;
              left: -1px;
              top: 50%;
              transform: translate(0, -50%);
              border: none;
              outline: none;
              box-shadow: none;
              width: 15px;
              height: 27px;
              background: url(../../../assets/images/product/prevarrow.png) no-repeat;
              z-index: 9;
              background-size: 100%;
              font-size: 0;
              &.slick-disabled {
                display: none !important;
              }
            }
            .slick-next {
              display: none;
              position: absolute;
              right: -1px;
              top: 50%;
              transform: translate(0, -50%);
              border: none;
              outline: none;
              box-shadow: none;
              width: 15px;
              height: 27px;
              background: url(../../../assets/images/product/nextarrow.png) no-repeat;
              z-index: 9;
              background-size: 100%;
              font-size: 0;
              &.slick-disabled {
                display: none !important;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
