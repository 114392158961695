@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.top-banner {
  &.hero {
    background-color: $black;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    color: $white;
    overflow: hidden !important;
    position: relative;

    @include mobile {
      min-height: 80vh;
    }

    &:after {
      content: "";
      width: 100%;
      height: 49px;
      position: absolute;
      bottom: 0;
      background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
      left: 0;
    }

    .container {
      display: flex;
      position: absolute !important;
      height: calc(100vh + 200px);
      justify-content: center;
      place-items: center;
      transform-style: preserve-3d;
      max-width: 100%;
      transition: all 4s ease-in-out;
      min-width: calc(100% + 400px);
      transform-origin: center;
      transform: translate(-50%, -50%) scale(1);
      left: 50%;
      top: 50%;
      will-change: transform;

      .hero_text {
        position: absolute;
        text-align: center;

        .pagetitle {
          background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
          text-align: center;
          font-family: "Inter";
          font-size: 32px;
          font-weight: bold;
          letter-spacing: -0.03px;
          text-align: center;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-transform: uppercase;
    
          @include mobile {
            font-size: 26px;
          }
        }
        .headtitle {
          font-family: "Inter";
          font-size: 50px;
          font-weight: 600;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: -0.5px;
          text-align: center;
          color: #ffffff;
          margin: 0 auto;
    
          @include mobile {
            font-size: 22px;
            padding-left: 15px;
            padding-right: 15px;
            max-width: 100%;
          }
        }
        .headcontent {
          color: rgba(255, 255, 255, 0.9);
          letter-spacing: -0.5px;
          line-height: 29px;
          font-size: 18px;
          text-align: center;
          max-width: 60%;
          margin: 0 auto;
    
          @include mobile {
            font-size: 15px;
            line-height: 1.5;
            padding-left: 10px;
            padding-right: 10px;
            max-width: 100%;
            margin-top: 10px;
          }
        }
      }
      .box {
        position: absolute;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          background-color: $black;
          width: 100%;
          height: 100%;
          content: '';
          z-index: 1;
          opacity: 0.8;
          transition: all 0.5s ease-in-out;
        }

        &:hover {
          &::before {
            opacity: 0.6;
            transition: all 0.5s ease-in-out;
          }
          .text {
            animation: fadeInUp 1s ease-in-out 0s forwards;
          }
        }



        img {
          position: relative;
          transition: all 1s ease-in-out;
        }
        .text {
          font-size: 16px;
          color: #fff;
          font-weight: 600;
          letter-spacing: -0.2px;
          text-align: left;
          line-height: 24px;
          opacity: 0;
          animation: fadeInOut 1s ease-in-out 0s forwards;
          position: absolute !important;
          top: auto !important;
          bottom: 30px !important;
          z-index: 9;
          width: 300px;
          // animation-delay: 1s;
        }
        &.box1 {
          top: 0;
          left: 480px;
          img {
            max-width: 383px;
          }
          .text {
            right: -150px;
          }
        }
        &.box2 {
          top: 123px;
          right: 0;
          img {
            max-width: 270px;
          }
          .text {
            right: 174px;
            text-align: right;
          }
        }
        &.box3 {
          top: 345px;
          left: 90px;
          img {
            max-width: 330px;
          }
          .text {
            left: 200px;
            text-align: left;
          }
        }
        &.box4 {
          top: 289px;
          right: 320px;
          img {
            max-width: 215px;
          }
          .text {
            left: 90px;
          }
        }
        &.box5 {
          bottom: 0;
          left: 0;
          img {
            max-width: 300px;
          }
          .text {
            left: 100px;
            text-align: left;
          }
        }
        &.box6 {
          bottom: -50px;
          left: 425px;
          img {
            max-width: 340px;
          }
          .text {
            left: 100px;
            text-align: left;
          }
        }
        &.box7 {
          bottom: -108px;
          left: calc(50% + 70px);
          img {
            max-width: 274px;
          }
          .text {
            left: 100px;
            text-align: left;
          }
        }
        &.box8 {
          bottom: 0;
          right: 0;
          img {
            max-width: 300px;
            display: flex;
            margin-left: auto;
          }
          .text {
            right: 120px;
            text-align: right;
          }
        }
      }
      @keyframes fadeInUp {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
      @keyframes fadeInOut {
        from {
          opacity: 1;
        }

        to {
          opacity: 0;
        }
      }
    }
    .scroll-text {
      position: absolute;
      bottom: 24px;
      font-size: 16px;
      left: 0;
      right: 0;
      margin: 0 auto;
      align-items: center;
      display: flex;
      justify-content: center;
      z-index: 1;
      color: $white;
      text-decoration: none;

      img {
        margin-right: 12px;
      }
    }
  }
}
@include mobile {
  .top-banner {
    &.hero {
      top: 0;
      left: 0;
      max-width: 100%;
      .container {
        min-height: calc(100% + 200px);
        .box {
          transition: all 1s ease-in-out;
          .text {
            display: none;
          }
          .mobile_click {
            display: none;
            @include mobile {
              display: flex;
              position: absolute;
              width: 20px;
              height: 20px;
              background: url('../../../assets/icons/plus-circle-solid.svg') no-repeat;
              z-index: 9;
              bottom: 10px;
              right: 10px;
            }
          }
          &.box1 {
            top: 95px;
            left: 300px;
            img {
              max-width: 164px;
            }
          }
          &.box2 {
            top: 136px;
            right: -40px;

            @include mobile {
              right: 20px;
            }
            img {
              max-width: 230px;
            }
          }
          &.box3 {
            top: 245px;
            left: 0;

            @include mobile {
              top: 135px;
            }
            img {
              max-width: 270px;
            }
          }
          &.box4 {
            top: 209px;
            right: 60px;

            @include mobile {
              right: -30px;
            }
            img {
              max-width: 225px;
            }
          }
          &.box5 {
            bottom: 100px;
            left: 30px;
            img {
              max-width: 250px;
            }
          }
          &.box6 {
            bottom: 91px;
            left: 313px;

            @include mobile {
              bottom: 191px;
            }
            img {
              max-width: 145px;
            }
          }
          &.box7 {
            bottom: 70px;
            left: 475px;
            img {
              max-width: 150px;
            }
          }
          &.box8 {
            bottom: 0;
            right: -195px;
            img {
              max-width: 150px;
            }
          }
        }
        .hero_text {
          width: 318px;
          left: 0;
          right: 0;
          margin: 0 auto;

          .headcontent {
            line-height: 1.3;
            margin-top: 10px;
          }
        }
      }
      .mobile_inner_text {
        display: none;
        &.show {
          display: flex;
          z-index: 99999;
          background: black;
          position: fixed !important;
          width: 100vw;
          padding-left: 20px;
          padding-right: 20px;
          height: 100%;
          font-size: 26px;
          left: 0;
          top: 0;
          color: #fff;
          font-weight: 600;
          letter-spacing: -0.2px;
          text-align: center;
          line-height: 1.5;
          align-items: center;
          justify-content: center;
          .cross {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 9;
            background: url('../../../assets/icons/times-circle-solid.svg');
            background-size: 100%;
          }
        }
      }
    }
  }
}
