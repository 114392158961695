@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.fuelling-section-style {
  width: 100%;
  background: $black;
  display: flex;
  flex-direction: column;
  color: $white;
  position: relative;
  padding: 50px 0px 250px;

  .content-tree {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;

    &:after {
      position: absolute;
      top: 0;
      left: 0%;
      width: 100%;
      height: 100%;
      content: "";
      background: url("../../../assets/shapes/triangle2.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      opacity: 0.6;
      transform: scale(1.4);

      @include mobile {
        top: 30px;
        background-size: cover;
        transform: scale(1.2);
      }
    }
  }

  .image-first {

    width: 100%;
  }

  .box {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;

    @include mobile {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 60px;
    }
    

    .icon-box {
      min-width: 80px;
      min-height: 80px;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      @include mobile {
        min-height: 55px;
      }
    }
    
    &:nth-child(1) {
      margin-top: 50px;
    }

    .description {
      margin: 0 auto;
      line-height: 1.7;
    }
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 50px;

    @include mobile {
      font-size: 28px;
      text-align: center;
    }
  }

  .title-sm {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;

    @include mobile {
      font-size: 28px;
    }
  }

  // achievement-sec
  .achievement-sec {
    margin-top: 200px;

    .achievement-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      @include mobile {
        margin-bottom: 100px;
      }
    }

    .title-lg {
      font-size: 50px;
      font-weight: bold;
      position: relative;
      z-index: 1;
    }

    .info-text {
      font-size: 22px;
      font-weight: 500;
      position: relative;
      z-index: 1;
    }

    .img1 {
      background: url("../../../assets/images/investment-page/bg/g1.png");
      background-size: cover;
      width: 100%;
      height: 300px;
      position: absolute;
    }
    .img2 {
      background: url("../../../assets/images/investment-page/bg/g2.png");
      background-size: cover;
      width: 100%;
      height: 291px;
      position: absolute;
    }
    .img3 {
      background: url("../../../assets/images/investment-page/bg/g3.png");
      background-size: cover;
      width: 100%;
      height: 302px;
      position: absolute;
    }

    .img1,
    .img2,
    .img3 {
      overflow: hidden;
      border-radius: 50%;
      animation: antiClockwiseSpin 15s infinite linear;

      @keyframes antiClockwiseSpin {
        from {
          transform: rotate(360deg);
        }
        to {
          transform: rotate(0deg);
        }
      }
    }
  }
}
