.product-root {
  overflow: scroll;
  height: 100vh;
  position: relative;
  scroll-snap-type: y proximity;
  overflow-x: hidden;
  nav {
    .hamburguer {
      .lines {
        background-color: #fff;
      }
    }
  }
  .teamAbout {
    background:#000;
    padding-bottom: 25px;
  }
}



