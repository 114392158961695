.about-root {
  position: relative;

  nav {
    .hamburguer {
      .lines {
        background-color: #fff;
      }
    }
  }

  .teamAbout {
    padding-bottom: 25px;
    margin-top: -50px;
  }
  .AppAnnie {
    background: #000;
  }
}
