@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";

.join-us-style {
  background-image: linear-gradient(to bottom, #b164fa, #2a48ef);
  position: relative;
  padding: 100px 0;
  z-index: 9999;

  @include mobile {
    padding: 53px 0;
  }

  .title {
    font-size: 65px;
    font-weight: 600;
    color: $white;

    @include mobile {
      font-size: 32px;
      text-align: center;
    }

    span {
      font-weight: 800;
    }

    br {

      @include mobile {
        display: none;
      }
    }
  }

  .join-button-section {
    @include mobile {
      margin-top: 50px;
    }
  }

  .description {
    font-size: 18px;
    font-weight: normal;
    color: $white;
    margin-top: 20px;

    @include mobile {
      text-align: center;
    }
  }

  .logo-box {
    margin-bottom: 65px;

    img {
      width: 73px;
    }
  }

  .icon {
    filter: contrast(0) brightness(100);
    width: 20px;
    margin-left: 10px;
  }

  .logo-small {
    text-align: center;

    img {
      max-width: 60px;
      margin: 0 auto;
    }
  }

  .active {
    transform: scale(0.8);
    animation: scaling 2s 2 ease-in forwards;

    @include desktop {
      @keyframes scaling {
        0% {
          transform: scale(0.8);
        }
        50% {
          transform: scale(1.2);
        }
        75% {
          transform: scale(0.8);
        }
        100% {
          transform: scale(1.2);
        }
      }
    }

    @include mobile {
      @keyframes scaling {
        0% {
          transform: scale(0.8);
        }
        50% {
          transform: scale(1.1);
        }
        75% {
          transform: scale(0.8);
        }
        100% {
          transform: scale(1.1);
        }
      }
    }
  }
}
