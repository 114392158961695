@import "../../../../styles/variables/mixins";
@import "../../../../styles/variables/colors";
.grid-title {
  color: white;
  justify-content: center;
  font-size: larger;
  text-align: center;
}
.logo-grid {
  padding: 10px;
  display: grid;

  grid-template-columns: auto auto;
  .icon-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .app-icon {
    height: 50px;
    width: 50px;
  }
  .item-color {
    color: whitesmoke;
  }
}
