@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.perkparent {
  width: 100%;
  border-radius: 80px 80px 0 0;
  padding: 56px 43px 0 43px;
  background: url('../../../assets/images/jobdetails/perkleft.svg') no-repeat,
    url('../../../assets/images/jobdetails/perkrightt.svg') no-repeat;
  background-size: 47%, 35%;
  background-position: bottom -150px left, bottom -142px right;
  position: relative;
  background-color: #fff;

  @include mobile {
    background-position: bottom 0 left, bottom 0 right;
    border-radius: 45px 45px 0 0;
    padding: 16px 16px 0 16px;
    background-size: 100%, 50%;
  }
  .perk_g_box {
    border-radius: 80px;
    background-image: linear-gradient(to bottom, #e9e9f2, rgba(255, 255, 255, 0));
    padding-top: 100px;
    @include mobile {
      border-radius: 45px;
      padding-top: 43px;
    }
    .content {
      width: 100%;
      max-width: 874px;
      text-align: center;
      color: #000000;
      font-size: 46px;
      font-weight: 500;
      letter-spacing: -0.2px;
      margin-left: auto;
      margin-right: auto;
      line-height: normal;
      @include mobile {
        max-width: 291px;
        font-size: 20px;
      }
    }
    .title {
      text-transform: uppercase;
      background-image: linear-gradient(to right, #56bbff, #a979ff, #ff6993);
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.2px;
      text-align: center;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-top: 100px;
      @include mobile {
        padding-top: 75px;
        font-size: 16px;
      }
    }
  }
  .contentbox {
    width: 100%;
    max-width: 1046px;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    @include mobile {
      flex-flow: column;
    }
    > div {
      &:before {
        content: "";
        width: 50px;
        height: 1px;
        background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
        position: absolute;
        top: 0;
        left: 0;
      }
      position: relative;
      padding-top: 27px;
      width: 100%;
      max-width: 463px;
      font-size: 30px;
      font-weight: normal;
      text-align: left;
      color: #000000;
      margin-bottom: 150px;
      
      @include mobile {
        max-width: 249px;
        font-size: 18px;
        margin-bottom: 60px;
        &:nth-child(even) {
          margin-left: auto;
        }
      }
    }
  }
  .seeyourself {
    padding-top: 167px;
    padding-bottom: 183px;
    .title {
      font-size: 48px;
      font-weight: 600;
      letter-spacing: -0.81px;
      text-align: center;
      color: #000000;
      padding-bottom: 25px;
      @include mobile {
        font-size: 24px;
      }
    }
    .content {
      font-size: 32px;
      font-weight: normal;
      letter-spacing: -0.81px;
      text-align: center;
      color: rgba($color: #000, $alpha: 0.7);
      @include mobile {
        font-size: 16px;
      }
    }
  }
}
