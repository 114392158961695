@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.meet-team-style {
  background-color: $black;
  position: relative;
  padding: 100px 0;
  min-height: 100vh;

  .info-box {
    color: $white;

    @include mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-bottom: 50px;
    }

    .title {
      font-size: 64px;
      font-weight: 800;

      @include mobile {
        font-size: 35px;
      }
    }

    .description {
      font-size: 18px;
      font-weight: normal;
      line-height: 1.44;
      margin-bottom: 20px;
      max-width: 70%;

      @include mobile {
        max-width: 100%;
        font-size: 16px;
        opacity: 0.7;
        text-align: center;
      }
    }
    .text-gradient {
      background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
      font-size: 22px;
      font-weight: 500;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  // Slider Primary Style
  .slider-primary {
    overflow: hidden;
    margin-top: -180px;

    // &:after {
    //   position: absolute;
    //   bottom: 100px;
    //   left: 0px;
    //   width: 100%;
    //   height: 380px;
    //   content: "";

    //   @include mobile {
    //     height: 520px;
    //     bottom: 178px;
    //     background-size: contain;
    //   }

    //   @include rwd(375) {
    //     height: 530px;
    //   }

    //   @include rwd(414) {
    //     height: 590px;
    //   }
    // }

    @include mobile {
      margin-top: 0;
    }

    .slider-main {
      max-width: 100%;
    }

    .content-section {
      width: 100%;
      height: 750px;
      &.is-selected {
        z-index: 1;
      }

      @include mobile {
        height: 654px;
      }
    }

    .content-box {
      display: flex;
      flex-direction: column;
      max-width: 60%;
      margin-right: 0;
      margin-left: auto;

      @include mobile {
        max-width: 80%;
      }
    }

    .title5 {
      color: $white;
      margin: 30px 0 15px;
      font-size: 36px;
      font-weight: 600;
      white-space: nowrap;
      a {
        color: $white;
        font-size: 30px;
        font-weight: 600;
        white-space: nowrap;
        text-decoration: none;
      }
      @include mobile {
        margin-top: 0;
      }
    }

    .text-lead {
      color: $white;
      font-size: 18px;
      font-weight: 600;
      color: #ffae3f;
      text-align: left;
    }
  }

  // Slider Secondary Style
  .slider-secondary {
    .slider-thumb {
      min-height: 200px;
      margin-top: 50px;
    }

    .image-thumb-box {
      width: 20%;
      margin-right: 20px;
      min-height: 290px;

      @include mobile {
        width: 44%;
        min-height: 250px;
      }

      .img-box {
        border-radius: 10px;
        overflow: hidden;
        height: 240px;
        max-height: 240px;
        position: relative;

        @include mobile {
          height: 200px;
          max-height: 200px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
      }
    }
  }

  .img-style {
    width: 100%;
    height: 650px;
    background-position: center;
    background-size: cover;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    padding: 10px;
    position: relative;

    @include mobile {
      height: 535px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0.9);
    }


    img {
      height: 100%;
      width: 89%;
      object-fit: cover;
      -webkit-mask-size: cover;
      mask-size: cover;

      @include mobile {
        height: 100%;
      width: 100%;
      }
    }

    .img-mask {
      -webkit-mask-image: url('../../../assets/shapes/mask.svg');
      mask-image: url('../../../assets/shapes/mask.svg'); 
      mask-repeat: no-repeat;
    }
  }

  .flickity-button {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: none;
    position: absolute;
    top: -190px;
    margin-right: 180px;

    @include mobile {
      top: -90px;
    }

    .flickity-button-icon {
      transform: scale(0.7);
    }

    &.next {
      right: 0px;
    }

    &.previous {
      right: 50px;
    }
  }

  .info-text {
    text-align: center;
    margin-top: 17px;

    .title5 {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.33;
      color: $white;
      a {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        color: $white;
        text-decoration: none;
      }
    }

    .text-lead {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.77;
      color: $white;
    }
  }
}
