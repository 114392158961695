@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.what-makes-style {
  background-color: $white;
  position: relative;
  padding: 0px 64px 50px;
  z-index: 9999;

  @include mobile {
    padding: 0;
  }

  .inner-section {
    border-radius: 80px;
    overflow: hidden;
    background-image: linear-gradient(to bottom, #e9e9f2, $white);
    padding: 50px;
    position: relative;

    &:after {
      position: absolute;
      top: -40px;
      left: 240px;
      width: 100%;
      height: 100%;
      content: "";
      background-image: url('../../../assets/shapes/squares.svg');
      background-repeat: no-repeat;
      background-position: left top;
      mix-blend-mode: overlay;
      // background: red;
      transform: rotate(-180deg);
      opacity: 0.7;

      @include desktop-lg {
        top: -100px;
        left: 200px;
      }
    }

    @include mobile {
      padding: 0px 20px 40px;
      text-align: center;
      background: none;
    }
  }

  .title {
    font-size: 66px;
    font-weight: 800;
    color: $black;

    @include mobile {
      font-size: 36px;
    }
  }

  .content-section {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .about-text, .btn, .title {
      position: relative;
      z-index: 1;
    }

    &:before {
      position: absolute;
      top: 0;
      right: -10px;
      width: 100%;
      height: 100vh;
      content: "";
      background-image: url('../../../assets/shapes/squares.svg');
      background-repeat: no-repeat;
      background-position: right top;
      mix-blend-mode: overlay;
    }
  }

  .about-text {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.43;
    max-width: 70%;
    margin: 0px 0 13px;
    opacity: 0.7;

    @include mobile {
      max-width: 100%;
      margin-bottom: 50px;
    }
  }

  .button-style {
    @include mobile {
      margin-top: 50px;
    }
  }

  .grid-section {
    @include mobile {
      background-image: linear-gradient(to bottom, #e9e9f2, $white);
      border-radius: 30px;
      padding-top: 20px;
    }
  }

  .grid-style {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 17px;
    column-gap: 17px;

    @include mobile {
      row-gap: 12px;
      column-gap: 12px;
    }

    .img-card {
      max-width: 100%;
    }
  }
  .career-btn {
    font-size: 16px;
    font-weight: 500;
    min-height: 44px;
    border-radius: 50px;
    min-width: 124px;
    transition: all 0.5s ease-in-out;
    background-color: #121212;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 124px;
    height: 44px;
    text-decoration: none;
    position: relative;
    z-index: 9;

    @include mobile {
      display: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
