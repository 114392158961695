@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.writetoUs {
  background: $white;
  padding: 100px 0;
  border-radius: 55px 55px 0 0;
  padding-bottom: 0;

  @include mobile {
    padding: 50px 0;
  }

  .titlearea {
    .title {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-size: 54px;
      font-weight: 800;
      letter-spacing: -1.55px;
      color: #000000;

      @include mobile {
        font-size: 28px;
      }
    }

    .text {
      font-size: 16px;
      letter-spacing: -0.2px;
      text-align: center;
      color: rgba($color: #000000, $alpha: 0.7);
      line-height: 24px;
      max-width: 50%;
      margin: 0 auto;

      @include desktop-lg {
        max-width: 35%;
      }

      @include mobile {
        font-size: 16px;
        max-width: 80%;

        br {
          display: none;
        }
      }
    }
  }

  .boxes_parent {
    position: relative;
    margin: 100px 0 50px;

    @include mobile {
      margin-left: 0px;
    }

    .container {
      .row {
        justify-content: center;
      }
    }

    // Image animation box
    .anim-box {
      height: 300px;
      width: 100%;
      // background-color: red;
      position: absolute;
      top: -120px;
      left: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
      }

      .animated-image {
        animation: rotation 15s infinite linear;

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(359deg);
          }
        }
      }
    }

    .box {
      margin-bottom: 100px;

      @include mobile {
        max-width: 75%;
        margin-bottom: 60px;

        &:nth-child(odd) {
          margin-right: auto;
        }
        &:nth-child(even) {
          margin-left: auto;
          .title {
            text-align: right;
          }
          .text {
            text-align: right;
          }
        }
      }

      .title {
        font-size: 18px;
        font-weight: 800;
        letter-spacing: -0.65px;
        color: rgba($black, 1);
        position: relative;
        z-index: 1;
        text-align: center;

        @include mobile {
          font-size: 16px;
          line-height: normal;
          padding-bottom: 10px;
          text-align: left;
        }
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.4px;
        color: rgba($black, 0.7);
        line-height: 28px;
        font-weight: normal;
        position: relative;
        text-align: center;
        z-index: 1;

        a {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.4px;
          color: rgba($black, 0.7);
          line-height: 28px;
          font-weight: normal;
          text-decoration: rgba($black, 0.7);
          @include mobile {
            text-align: left;
          }
        }

        @include mobile {
          font-size: 15px;
          font-weight: 500;
          max-width: 100%;
          text-align: left;

          a {
            font-size: 18px;
          }
        }
      }
    }
  }

  .culture-box {
    position: relative;
    min-height: 100vh;

    @include mobile {
      min-height: auto;
      padding: 0 15px 100px;
    }
  }
  .culture_images_box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 9;
    width: 70%;
    flex-wrap: wrap;
    margin: 0 auto;
    left: 0;
    right: 0;

    @include mobile {
      width: 100%;
    }

    .cultureimgpart {
      padding: 8px;
      width: 20%;
      position: absolute;
      transition: all 0.5s ease-in-out;

      @include mobile {
        width: 100%;
        position: relative;
      }

      &:nth-child(1) {
        left: 30%;
        top: 0px;

        @include mobile {
          left: 0%;
        }
      }

      &:nth-child(2) {
        right: 30%;
        top: 0px;

        @include mobile {
          right: 0%;
          top: 0px;
        }
      }

      &:nth-child(3) {
        left: 30%;
        top: 35%;

        @include mobile {
          left: 0%;
          top: 0%;
        }

        @include desktop-lg {
          top: 38.5%;
        }
      }

      &:nth-child(4) {
        right: 30%;
        top: 35%;

        @include mobile {
          left: 0%;
          top: 0%;
        }

        @include desktop-lg {
          top: 38.5%;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .animationActive {
    min-height: 80vh;

    .culture_images_box {
      transition: all 0.5s ease-in-out;

      .cultureimgpart {
        padding: 8px;
        width: 20%;
        position: absolute;
        transition: all 0.5s ease-in-out;

        &:nth-child(1) {
          left: 10%;
          top: 0px;
        }

        &:nth-child(2) {
          right: 50%;
          top: 0px;
        }

        &:nth-child(3) {
          left: 50%;
          top: 0%;
        }

        &:nth-child(4) {
          right: 10%;
          top: 0;
        }

        img {
          width: 100%;
        }
      }
    }

    &::after {
      content: "";
      width: calc(10% - 15px);
      background-image: linear-gradient(to right, #eae9f5, #ffffff);
      height: 44vh;
      position: absolute;
      right: -20px;
      top: -20px;
      border-radius: 50px 0 0 50px;
      transform: all 0.5s ease-in-out;
    }

    &:before {
      background-image: linear-gradient(to left, #eae9f5, #ffffff);
      margin-right: 15px;
      position: absolute;
      content: "";
      width: calc(10% - 15px);
      top: -20px;
      left: -20px;
      height: 44vh;
      content: "";
      border-radius: 0 50px 50px 0;
      transform: all 0.5s ease-in-out;
    }
  }
}
