@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.philosophy-section-style {
  min-height: 500px;
  width: 100%;
  background-image: linear-gradient(to bottom, #ac63fa, #424df1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;

  @include mobile {
    min-height: auto;
    padding: 100px 0;
  }

  .title {
    font-size: 50px;
    font-weight: 600;

    @include mobile {
      font-size: 38px;
      margin: 10px 0 20px;
    }
  }
  
  .info {
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    max-width: 50%;
    margin: 0 auto;
    opacity: 0.8;

    @include mobile {
      max-width: 100%;
    }
  }
}
