@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.top-banner-contact {
  position: relative;

  .background-container {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 60vh;

      @include mobile {
        object-fit: contain;
        transform: rotate(90deg) scale(1.8);
      }
    }
  }

  .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    max-width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
    z-index: 999;

    .hero_text {
      position: relative;
      top: 0;
      left: 0;
      text-align: center;

      .pagetitle {
        background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
        text-align: center;
        font-family: "Inter";
        font-size: 45px;
        font-weight: bold;
        letter-spacing: -0.03px;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;

        @include mobile {
          font-size: 30px;
        }
      }
      .headtitle {
        font-family: "Inter";
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.5px;
        text-align: center;
        color: #ffffff;
        max-width: 460px;
      }
      .headcontent {
        color: white;
        font-size: 20px;
        max-width: 60%;
        margin: 0 auto;

        @include mobile {
          max-width: 90%;
          font-size: 16px;
        }
      }
    }
  }
}
