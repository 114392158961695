@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.combined-section {
  background: $black;
  position: relative;

  @include desktop-lg {
    min-height: 100vh;
  }

  .heading-section {
    margin: 0 auto;
    text-align: center;
    padding-top: 100px;

    @include mobile {
      max-width: 80%;
    }

    br {
      @include mobile {
        display: none;
      }
    }

    .title-lg {
      color: $white;
      font-weight: bold;
      font-size: 46px;
      line-height: 1.3;

      @include mobile {
        font-size: 30px;
      }
    }

    .description {
      color: $white;
      opacity: 0.7;
      font-size: 18px;
      margin-top: 5px;

      @include mobile {
        font-size: 14px;
      }
    }
  }
  .mobile-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;

    .grid-item-title {
      margin: 25px;
      background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
      text-align: center;
      font-family: "Inter";
      font-size: 32px;
      font-weight: bold;
      letter-spacing: -0.03px;
      text-align: center;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;

      @include mobile {
        font-size: 26px;
      }
    }
    .enable-container {
      background: linear-gradient(180deg, rgba(138, 100, 247, 0.2) 0%, rgba(169, 121, 255, 0) 100%);
      border-radius: 50px 50px 5px 5px;
      width: 46%;

      .enable-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }

      .app-logos-container {
        width: 47%;
      }
    }

    .engage-container {
      background: linear-gradient(180deg, rgba(138, 100, 247, 0.2) 0%, rgba(169, 121, 255, 0) 100%);
      width: 24%;
      border-radius: 50px 50px 5px 5px;
    }

    .elevate-container {
      background: linear-gradient(180deg, rgba(138, 100, 247, 0.2) 0%, rgba(169, 121, 255, 0) 100%);
      border-radius: 50px 50px 5px 5px;
      width: 24%;
    }

    .app-logos-container {
      position: relative;
      padding-top: 40px;
      background: url("../../../assets/images/display.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 90%;
      margin: 0 auto;
    }
  }
}
