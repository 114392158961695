@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

// achievement-sec
.rating-counter-sec {
  padding: 150px 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    padding: 90px 0 0px;
  }

  .achievement-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @include mobile {
      margin-bottom: 70px;
      align-items: flex-start;
      text-align: center;
    }
  }

  .padding-sm {

    @include mobile {
      padding: 5px;
    }
  }

  .title-lg {
    font-size: 50px;
    font-weight: bold;
    position: relative;
    z-index: 1;

    @include mobile {
      font-size: 26px;
      text-align: center;
      width: 100%;
    }
  }

  .info-text {
    font-size: 22px;
    font-weight: 500;
    position: relative;
    z-index: 1;

    @include mobile {
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      width: 100%;
    }
  }


  .img-sec {
    overflow: hidden;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    position: absolute;
    animation: antiClockwiseSpin 10s infinite linear;

    @include mobile {
      width: 100%;
      height: 100;
      border-radius: 50%;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: contain;
      transform: scale(1.3);
      left: 0;

      @include mobile {
        transform: scale(1.6);
      }
    }

    @keyframes antiClockwiseSpin {
      from  {
        transform: rotate(360deg);
     }
     to {
        transform: rotate(0deg);
      }	
   }
  }
}