@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.productbanner {
  position: relative;
  background: $black;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 100px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: url("../../../assets/images/product/herobg.png");
    opacity: 0.4;
  }

  &.hero {
    color: $white;

    .box_main {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .banner-section {
      display: flex;
      align-items: center;
      flex-direction: column;

      @include mobile {
        transform: scale(1.5);
        margin-top: 80px;
      }

      img {
        animation: spin 60s linear infinite;

        @keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        @include mobile {
          width: 100%;
          opacity: 0.3;
        }
      }
    }

    .hero_text {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @include mobile {
        max-width: 80%;
        transform: scale(0.8);
      }


      .pagetitle {
        background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-weight: bold;
        letter-spacing: -0.03px;
        text-align: center;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;

        @include mobile {
          font-size: 20px;
        }
      }
      .headtitle {
        font-family: "Inter";
        font-size: 30px;
        font-weight: 600;
        font-style: normal;
        line-height: 1.3;
        text-align: center;
        color: $white;

        @include mobile {
          font-size: 22px;
          max-width: 100%;
        }
      }

      .headcontent {
        color: rgba($white, 0.6);
        font-weight: 500;
        font-size: 14px;
        max-width: 80%;
        margin: 0 auto;
        word-break: break-word;

        @include mobile {
          font-size: 16px;
          padding-left: 10px;
          padding-right: 10px;
          max-width: 100%;
        }
      }
    }
  }
}
