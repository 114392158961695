@import "../../../src/styles/variables/mixins";
@import "../../../src/styles/variables/colors";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.primelist {
  padding-left: 1.3rem;
  font-size: 14px;
}

.appyhighprimeques {
  color: #212529;
  font-weight: 700;
}

#tagline {
  font-size: 14px;
  font-weight: 700;
  color: rgb(196, 184, 253);
  cursor: pointer;
  text-transform: uppercase;
  padding: 1rem 0 1rem 1rem;
  text-align: center;
  @media (max-width: 400px) {
    padding: 1rem 0 1rem 0;
  }
}

.purple {
  color: #fff;
  font-weight: 800;
}

.top-banner-contact {
  position: relative;
  background-color: black;

  .form-container {
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-bottom: 1px solid black;
    @media (max-width: 968px) {
      flex-direction: column;
    }
  }
}
.left-container {
  width: 50%;
  height: 650px;
  // height: max-content;
  position: relative;
  top: 70px;
  background-color: #f5f1ff;
  padding: 24px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 968px) {
    width: 100%;
    height: 600px;
    padding: 24px;
    margin: 0px;
  }
  @media (max-width: 425px) {
    height: 650px;
  }
  @media (max-width: 350px) {
    height: 680px;
  }
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 50%;
  height: 650px;
  position: relative;
  top: 70px;
  color: rgb(54, 65, 82);
  padding: 24px;
  box-shadow: none;
  background-image: none;
  margin-bottom: 25px;
  border: 1px solid rgba(144, 202, 249, 0.145);
  @media (max-width: 968px) {
    height: 600px;
    width: 100%;
    margin: 0px;
    top: 0px;
  }
  @media (max-width: 320px) {
    height: 650px;
  }
}
.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-width: 100%;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  z-index: 999;

  .hero_text {
    position: relative;
    top: 0;
    left: 0;
    text-align: center;

    .pagetitle {
      background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
      text-align: center;
      font-family: "Inter";
      font-size: 45px;
      font-weight: bold;
      letter-spacing: -0.03px;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;

      @include mobile {
        font-size: 30px;
      }
    }
    .headtitle {
      font-family: "Inter";
      font-size: 36px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.5px;
      text-align: left;
      color: #ffffff;
      max-width: 460px;
    }
    .headcontent {
      color: white;
      font-size: 20px;
      max-width: 60%;
      margin: 0 auto;

      @include mobile {
        max-width: 90%;
        font-size: 16px;
      }
    }
  }
}

.singleRow {
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 10px;
}

.input-container {
  position: relative;
  width: 90%;
}

.fInput {
  margin-right: 20px;
}

.file-input {
  // position: relative;
  box-sizing: content-box;
  width: 90%;
  margin-top: 8px;
  margin-left: 20px;
  height: max-content;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-weight: 500;
  padding: 12px 0px 12px 20px;
  border-radius: 12px;

  // height: 4em;
}

.ic1 {
  margin-top: 8px;
}

.form-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 1rem;
  margin-bottom: 2rem;
}
.form-head h2 {
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  line-height: 1.2;
  color: rgb(103, 58, 183);
  @media screen and (max-width: 350px) {
    font-size: 1.3rem;
  }
}
// .form-head span {
//   margin-top: 8px;
//   color: rgb(105, 117, 134);
//   font-weight: 400;
//   font-family: Roboto, sans-serif;
//   line-height: 1.66;
//   font-size: 16px;
//   text-align: inherit;
// }
.form-subhead {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 500px) {
  .form-subhead {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.form-subhead h6 {
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  font-family: Roboto, sans-serif;
  line-height: 1.75;
  @media screen and (max-width: 350px) {
    font-size: 0.8em;
  }
  @media screen and (max-width: 310px) {
    font-size: 0.75em;
  }
}
.outerlogo {
  position: relative;
  background-color: #000;
  margin: 30px auto 24px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
  padding: 1rem;
  width: 90%;
}
.outerlogo img {
  padding: 10px;
}

@media screen and (max-width: 610px) {
  .largeScreen {
    display: none;
  }
  .smallScreen {
    display: block;
  }
}
@media screen and (min-width: 610px) {
  .largeScreen {
    display: block;
  }
  .smallScreen {
    display: none;
  }
}
.file-label {
}
.inputfile {
  width: 100%;
}

.input {
  border: 0px;
  box-sizing: content-box;
  height: 1em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 94%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  color: rgb(18, 25, 38);
  font-weight: 500;
  padding: 24px 0px 12px 20px;
  border-radius: 12px;
  width: 100%;

  @media (max-width: 600px) {
    height: 34;
  }
}
.error-border {
  border: 2px solid rgba(246, 0, 0, 0.23);
}
.succ-border {
  border: 2px solid rgb(0, 190, 92);
}

.cut {
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: 5px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.cut-short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}
.placeholderError {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  top: 23px;
  left: 300px;
  color: rgb(247, 74, 69);
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-10px) translateX(0px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: rgb(103, 58, 183);
}
.succmessage {
  font-size: 18px;
  margin: 10px 0px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgb(0, 162, 78);
  color: #fff;
}
.errmessage {
  font-size: 18px;
  margin: 10px 0px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f44336;
  color: white;
  @media screen and (max-width: 350px) {
    font-size: 14px;
  }
}
.submitbtn {
  margin-top: 25px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;

  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-transform: capitalize;
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 8px 22px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: black;
  background-color: #f5f1ff;
  width: 94%;
  margin-left: 20px;
  box-shadow: none;
  font-weight: 500;
  border-radius: 4px;
  @media (max-width: 968px) {
    width: 93%;
  }
  @media (max-width: 768px) {
    width: 93%;
  }
  @media (max-width: 450px) {
    width: 97%;
  }
}
.submitTxt {
  font-weight: 600;
}

.snackbar-container {
  display: flex;
  justify-content: center;
  padding: 10px;
}

#snackbar {
  visibility: hidden;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 10000;
  bottom: 30px;
  margin: 10px;

  @media (max-width: 968px) {
    bottom: 30px;
  }
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
