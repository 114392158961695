@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";

.footer-style {
  background-color: $white;
  position: relative;
  padding: 80px 0;
  min-height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  @include mobile {
    padding: 30px 0;
    min-height: auto;
  }

  .mobile-footer {
    text-align: center;
    padding: 20px 0;

    @include desktop {
      display: none;
    }

    .links-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: auto;

      .link-item {
        font-size: 14px;
        font-weight: 800;
        color: $textColor;
        margin-bottom: 42px;
        cursor: pointer;
        text-transform: uppercase;
      }
    }

    .logo-mobile {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 30px;
    }

    .about-company {
      font-size: 15px;
      opacity: 0.7;
      max-width: 90%;
      margin: 0 auto 45px;
    }

    .social-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 50%;
      margin: 0 auto 20px;

      .link-item {
        background: $textColor;
        color: $white;
        width: 38px;
        height: 38px;
        display: block;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 15px;
        filter: contrast(0) brightness(100);
      }

      .playstore {
        img {
          filter: none;
          margin-left: 3px;
        }
      }
      a {
        text-decoration: none;
        color: inherit;
      }
    }

    .other-links {
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        display: none;
      }

      .link-item {
        margin-right: 12px;
        font-size: 12px;
        color: $textColor;
        font-weight: 800;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .copyright-mob {
      font-size: 12px;
      color: $textColor;
      font-weight: 800;
    }
  }

  .desktop-footer {
    width: 100%;
    @include mobile {
      display: none;
    }

    .title {
      font-size: 14px;
      color: $black;
      text-transform: uppercase;

      a {
        font-size: 14px;
        font-weight: 600;
        color: $black;
      }
    }

    .about-text {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.43;
      max-width: 81%;
      margin-top: 13px;
    }

    .links {
      display: flex;
      flex-direction: column;

      .link-item {
        font-size: 14px;
        color: $black;
        opacity: 0.7;
        margin-top: 11px;
        cursor: pointer;
      }
    }

    .two-things {
      display: flex;
      font-size: 1rem;
    }
    .social-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 50%;
      margin: 0 auto 20px;

      .link-item {
        background: $textColor;
        color: $white;
        width: 38px;
        height: 38px;
        display: block;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 15px;
        filter: contrast(0) brightness(100);
      }

      .playstore {
        img {
          filter: none;
          margin-left: 3px;
        }
      }
    }

    .description {
      font-size: 18px;
      font-weight: normal;
      line-height: 1.44;
      margin-bottom: 20px;
      max-width: 70%;
    }

    .logo-img {
      width: 200px;
    }
  }
  .logoicon {
    height: 50px;
  }

  a {
    text-decoration: none;
    color: inherit;
    font-size: 12px;
  }
}
