@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.jobdetailhero {
  background: #fafafa;
  
  .header {
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      padding-bottom: 90px;

       img {
        max-width: 147px;
      }
    }

  }
  .back-btn-style {
    background: none;
    border: none;
    color: $black;
    border-radius: 50px;
    font-weight: 600;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    font-size: 18px;

    @include mobile {
      position: absolute;
      justify-content: center;
      top: 20px;
      right: 10px;
      width: 80px;
      font-size: 15px;
      text-transform: uppercase;
    }

    img {
      width: 8px;
      margin-right: 10px;
      
      @include mobile {
        margin-right: 5px;
        width: 8px;
      }
    }
  }

  .container {
    padding-top: 50px;

    @include mobile {
      padding-top: 30px;
    }

    .headertitlebox {
      display: flex;
      flex-flow: row wrap;

      .titlesec {
        display: flex;
        flex-direction: column;
        width: calc(100% - 172px);

        @include mobile {
          width: 100%;
        }

        .title {
          text-align: left;
          background-image: linear-gradient(to top, #56bbff, #a979ff, #ff6993);
          font-size: 24px;
          font-weight: bold;
          text-align: left;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;

          @include mobile {
            text-align: center;
            width: 100%;
            margin-bottom: 12px;
            font-size: 22px;
          }
        }
      }
      .button {
        width: 172px;
        height: 60px;
        border-radius: 32px;
        background: $theme-purple-light;
        letter-spacing: -0.56px;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #fff;
        
        @include mobile {
          width: 124px;
          height: 44px;
          margin-left: auto;
          margin-right: auto;
          order: 3;
        }
      }
      .subtitlesec {
        font-size: 48px;
        font-weight: 600;
        line-height: 1.3;
        text-align: left;
        color: $black;

        @include mobile {
          width: 100%;
          text-align: center;
          font-size: 26px;
          margin-bottom: 32px;
        }
      }
    }
    .herosecimagebox {
      height: 405px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      top: 80px;

      @include mobile {
        height: 195px;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        object-fit: cover;
      }
    }
  }
}
