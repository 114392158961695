@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";

.navbar-style {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
  padding: 0 40px;

  @include mobile {
    padding: 0 24px;
  }

  .logo.white {
    filter: contrast(0) brightness(100);
    width: 165px;
  }

  .navbar {
    background: none;
    min-height: 70px;

    @include mobile {
      padding: 0;
    }
  }

  .navbar-brand {
    position: relative;
    z-index: 1;

    @include mobile {
      margin-top: 10px;
    }
  }

  .nav-link {
    color: $white;
    margin-left: 35px;
    font-size: 16px;
    font-weight: 700;
  }

  .navbar-collapse {
    justify-content: flex-end;
  }

  &.theme-dark {
    .nav-link {
      color: $black;

      @include mobile {
        color: $white;
      }
    }

    .dark {
      max-width: 30px;
    }
  }

  // hamburguer animation
  .navbar-toggler {
    width: 30px;
    height: 30px;
    padding: 0;
    position: relative;
    z-index: 1;

    .lines {
      background-color: $white;
      width: 100%;
      height: 4px;
      margin: 5px 0;
      border-radius: 50px;
      transition: all 450ms ease-in;
      overflow: hidden;
    }

    .close-hamburguer {
      .lines {
        cursor: pointer;
        background-color: $white;
      }
      .line-top {
        transform: translateY(250%) rotate(45deg);
      }
      .line-mid {
        opacity: 0;
      }
      .line-bottom {
        transform: translateY(-200%) rotate(135deg);
      }
    }
  }

  // Menu active style
  &.menu-mobile {
    .navbar-collapse {
      display: block;
      background-color: $black;
      position: fixed;
      width: 100%;
      height: 100vh;
      left: -100%;
      top: 0;
      bottom: 0;
      padding: 100px 20px 0;
      transition: all 0.3s ease-in-out;
    }

    .nav-item {
      @include mobile {
        transform: translateY(2%);
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        opacity: 0;
        position: relative;
        margin-bottom: 20px;

        &:after {
          content: "";
          position: absolute;
          height: 4px;
          width: 0%;
          left: 0;
          bottom: 0;
          background-color: $white;
          transition: width 0.5s ease-in-out;
        }
      }
      .nav-link {
        color: $white;
        margin-left: 0;
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 0px;
      }
    }

    &.menu-active {
      position: fixed;
      .navbar-collapse {
        left: 0;
        transition: all 0.3s ease-in-out;
      }
      .navbar-toggler {
        img {
          @include mobile {
            filter: none;
          }
        }

        .dark-bar {
          @include mobile {
            filter: contrast(0) brightness(100);
          }
        }
      }

      .nav-item {
        opacity: 1;
        transform: translateX(0%);

        &:hover:after {
          width: 100%;
        }

        &:nth-child(1) {
          transition-delay: 0.3s;
        }
        &:nth-child(2) {
          transition-delay: 0.4s;
        }
        &:nth-child(3) {
          transition-delay: 0.5s;
        }
        &:nth-child(4) {
          transition-delay: 0.6s;
        }
        &:nth-child(5) {
          transition-delay: 0.7s;
        }
      }

      .navbar-brand {
        img {
          @include mobile {
            filter: contrast(0) brightness(100);
          }
        }
      }
    }
  }

  &.theme-light {

    .lines {
      background-color: $white;
    }
  }

  .is-active {
    color:$theme-info;
  }
}
