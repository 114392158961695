@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.Storyboardsec {
  background: $black;
  position: relative;
  padding-top: 100px;
  padding-left: calc(calc(100% - 1100px) / 2);
  @include mobile {
    padding-left: 25px;
    margin-top: -1px;
    padding-bottom: 131px;
  }
  .heading {
    letter-spacing: -0.68px;
    text-align: left;
    color: $white;
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 40px;
    @include mobile {
      font-size: 24px;
    }
  }
  .storyboard_slider_parent {
    width: 100%;
    position: relative;
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    @include mobile {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    > div {
      transition: all 0.4s ease-in-out;
      min-width: calc(100% / 6 - 35px);
      display: flex;
      height: 532px;
      overflow: hidden;
      opacity: .3;
      @include mobile {
        min-width: calc(100% - 30px);
        height: auto;
        opacity: 1;
        padding-right: 10px;
      }
      &:before {
        content: "";
        min-width: 1px;
        background: rgba(255, 255, 255, 0.1);
        margin-right: 10px;
      }
      .contentbox {
        max-width: calc(100% - 20px);

        @include mobile {
          max-width: calc(100% - 0px);
          opacity: 1;
        }
        .date {
          letter-spacing: -0.42px;
          color: #ffffff;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 165px;
          @include mobile {
            font-size: 18px;
            margin-bottom: 140px;
          }

          span {
            transform: rotate(-90deg);
            display: flex;
            max-width: 134px;
            margin-left: -60px;
          }
        }
        .text {
          color: rgba($color: $white, $alpha: 0.29);
          background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
          font-size: 24px;
          line-height: normal;
          letter-spacing: -0.2px;
          text-align: left;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 400;
          max-width: 100%;
          @include mobile {
            background-image: none;
            -webkit-background-clip: text;
            background-clip: border-box;
            -webkit-text-fill-color: #fff;
            padding-right: 0;
            font-size: 18px; 
          }

          @include iphone5 {
            font-size: 16px;
            line-height: 1.4;
          }

          .innertext {
            overflow: hidden;
            width: 508px;
            @include mobile {
              width: 100%;
              padding-right: 10px;
            }
          }
          .milestones {
            margin-top: 80px;
            font-size: 16px;
            font-weight: 400;
            display: flex;
            opacity: 1;
            transition: all 1s ease-in-out;
            align-items: flex-start;
            padding-right: 45px;
            white-space: nowrap;
            bottom: 50px;
            position: absolute;

            @include mobile {
              padding-right: 0;
              position: relative;
              opacity: 1;
              color: #fff;
              white-space: break-spaces;
            }

            &::before {
              content: "";
              margin-right: 5px;
              width: 20px;
              height: 20px;
              background: url(../../../assets/images/aboutus/flag.png) no-repeat;
              background-size: 100%;
              margin-top: 2px;
            }
          }
        }
      }
      &.active {
        min-width: 570px;
        opacity: 1;
        .text {
          .milestones {
            opacity: 1;
            padding-right: 0;
          }
        }
        @include mobile {
          min-width: calc(100% - 30px);
        }

        .contentbox {
          cursor: pointer;
          .date {
            margin-bottom: 140px;
          }
          .text {
            background-image: none;
            -webkit-background-clip: text;
            background-clip: border-box;
            -webkit-text-fill-color: #fff;
            padding-right: 41px;
            @include mobile {
              font-size: 18px;
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  padding-bottom: 150px;
  &:after {
    content: "";
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed rgba($color: #e9eaf2, $alpha: 0.3);
    display: block;
    position: absolute;
    left: 0;
    bottom: 130px;
  }
}
