@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.word-section-style {
  background-color: $white;
  border-radius: 80px;
  position: relative;
  padding: 50px;

  @include mobile {
    padding: 50px 0 0;
  }

  .title {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    margin: 0 0 75px;

    @include mobile {
      font-size: 28px;
      max-width: 70%;
      margin: 0 auto 75px;
    }
  }

  .logo-box {
    margin-bottom: 65px;

    img {
      width: 73px;
    }
  }

  .logo-small {
    text-align: center;

    img {
      max-width: 60px;
      margin: 0 auto;
    }
  }

  .content-box {
    position: relative;
    margin-bottom: 150px;

    @include mobile {
      margin-bottom: 80px;
      max-width: 80%;
    }

    .title-sm {
      font-size: 25px;
      font-weight: 700;
      letter-spacing: -0.85px;
      max-width: 65%;
      text-align: center;
      margin: 0 auto;
      position: relative;
      z-index: 1;

      @include mobile {
        font-size: 20px;
        max-width: 100%;
        text-align: left;
      }

      br {
        @include mobile {
          display: none;
        }
      }
    }

    .description {
      opacity: 0.7;
      font-size: 18px;
      font-weight: 500;
      color: rgba($black, 0.6);
      text-align: center;
      position: relative;
      z-index: 1;

      @include mobile {
        text-align: left;
        margin-top: 10px;
      }
    }

    .imgBg {
      position: absolute;
      width: 400px;
      height: 400px;
      border-radius: 50%;
      overflow: hidden;
      // background-color: red;
      animation: antiClockwiseSpin 15s infinite linear;

      @keyframes antiClockwiseSpin {
        from {
          transform: rotate(360deg);
        }
        to {
          transform: rotate(0deg);
        }
      }

      @include mobile {
        width: 300px;
        height: 300px;
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
      }
    }

    &.box1 {
      .title-sm {
        color: #391162;
      }

      .imgBg {
        top: -80px;
        left: 100px;
        width: 350px;
        height: 350px;
        animation: clockwiseSpin 15s infinite linear;

        @keyframes clockwiseSpin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        @include mobile {
          top: -58px;
          left: -80px;
          width: 250px;
          height: 250px;
        }
      }

      @include mobile {
        padding-left: 0px;
      }
    }
    &.box2 {
      .imgBg {
        top: -120px;
        left: 60px;

        @include mobile {
          top: -100px;
          left: -20px;
          opacity: 0.73;
          transform: rotate(-79deg) scale(1.5);
        }
      }
      .title-sm {
        color: #034143;
      }

      @include mobile {
        margin-right: 0;
        margin-left: auto;
      }
    }
    &.box3 {
      .imgBg {
        top: -120px;
        left: 50px;

        @include mobile {
          top: -90px;
          left: -70px;
          transform: rotate(19deg) scale(1.5);
        }
      }
      .title-sm {
        color: #034143;
      }

      @include mobile {
        margin-right: 0;
        margin-left: auto;
      }
    }
    &.box4 {
      .imgBg {
        top: -120px;
        right: 70px;

        @include mobile {
          top: -60px;
          left: -30px;
          transform: rotate(27deg) scale(1.9);
        }
      }
      .title-sm {
        color: #391162;
      }
    }
  }

  .order1 {
    @include mobile {
      order: 2;
    }
  }
  .order2 {
    @include mobile {
      order: 1;
    }
  }
}
