@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.wherweWork {
  background-image: linear-gradient(to bottom, #b164fa, #2a48ef);
  position: relative;
  padding: 100px 0;

  @include mobile {
    padding: 50px 0 100px;
  }
  .mapimg {
    max-width: 100%;
  }
  .descarea {
    @include mobile {
      margin-top: 40px;
      text-align: center;
    }
    .title {
      font-weight: 500;
      font-size: 35px;
      color: #fbfbfd;
      line-height: 42px;
      letter-spacing: -0.647176px;
    }
    .content {
      padding-top: 10px;
      color: $white;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.4px;
      max-width: 431px;
    }
    a {
      display: flex;
      margin-top: 26px;
      letter-spacing: -0.225px;
      text-decoration-line: underline;
      color: #fbfbfd;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;

      @include mobile {
        justify-content: center;
      }
    }
  }

  iframe {
    width: 100%;
    height: 350px;
    border: none;
    border-radius: 6px;
    border: 6px solid $white;
    filter: grayscale(100);
  }
}
