@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";

.home-page-container {
  overflow: hidden;

  .hero-section {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    background-color: red;

    h1 {
      font-weight: bold;
      font-size: 100px;
      text-transform: uppercase;
    }
  }
}

.emptySection {
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

.checkbox-items {
  height: 100px;
  background-color: red;
}