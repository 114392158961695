@import "../../../styles/variables/mixins";
@import "../../../styles/variables/colors";

.vision_sec {
  background: $black;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;

  @include mobile {
    min-height: auto;
    padding: 50px 20px;
    overflow: hidden;
  }

  &::before {
    content: "";
    background: url("../../../assets/images/aboutus/visionbg.png") no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;

    @include mobile {
      transform: rotate(62deg) scale(2);
      background-size: contain;
    }
  }

  &::after {
    content: "";
    background: linear-gradient(to bottom, transparent 0%, black 100%);
    width: 100%;
    height: 20%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .item-box {
    text-align: center;

    @include mobile {
      margin-bottom: 50px;
      text-align: left;
    }

    &:nth-child(2) {
      @include mobile {
        text-align: right;
      }

      .content {
        margin-left: auto;
      }
    }
  }

  .box_area {
    position: relative;
    display: flex;
    img {
      max-width: 100%;

      @include mobile {
        display: none;
      }
    }

    .title {
      line-height: 48px;
      letter-spacing: -0.63px;
      color: $white;
      font-size: 42px;
      font-weight: 800;

      @include mobile {
        font-size: 32px;
      }
    }
    .content {
      line-height: 26px;
      letter-spacing: -0.18px;
      font-size: 16px;
      color: rgba($white, 0.9);
      font-weight: 500;

      @include mobile {
        max-width: 95%;
      }
    }

    .visionbox_parent {
      position: relative;
      z-index: 1;
    }
  }
}
